import React, { useEffect, useState } from "react";


import './OverlayStyle.css'




const Overlay = ({enabled}) => {
    return (
        <>
            {enabled ? (
                <div className="overlay" >
                    <div className="overlay__inner">
                        <div className="overlay__content"><span className="spinner"></span></div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    )

}

export default Overlay;