import React from "react";

const Footer = () => {
    return (
        <>
            <footer>
                <div id="contactus" className="g-color-gray-light-v1 g-py-70" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-5 g-mb-30 g-mb-0--md">
                                <div className="u-heading-v2-2--bottom g-brd-gray-dark-v2 g-mb-15">
                                    <h2 className="u-heading-v2__title text-uppercase g-font-weight-600 g-font-size-18 g-color-black g-mb-minus-15">SMILEPLUS</h2>
                                </div>

                                <p className="text-uppercase g-letter-spacing-1_5 g-font-weight-600 g-theme-color-gray-light-v2- g-color-black g-mb-30 g-mb-60--md">SMILE HAPPINESS บริการครบวงจร จากพาราวินเซอร์ มาพร้อมโปรโมชั่นสุดพิเศษในที่เดียว</p>

                            </div>

                            <div className="col-md-6 col-lg-7">
                                <p className="g-font-size-default g-color-black g-mb-30 g-font-weight-700">
                                    สไมล์พลัส เว็ปแอปพลิเคชั่น บริการใหม่จากพาราวินเซอร์ มาพร้อมให้บริการครบวงจรด้านรถยนต์รวมถึงสินค้าและบริการต่างๆในเครือพาราวินเซอร์ ซึ่งเป็นตัวแทนจำหน่ายรถยนต์โตโยต้าในประเทศไทย ซึ่งจะรวบรวมโปรโมชั่นและส่วนลดมากมายไว้ครบจบในที่เดียว
                                </p>
                                <address className="text-uppercase mb-0">
                                    <div className="d-flex g-mb-15">
                                        <div className="u-icon-v3 u-icon-size--sm g-width-35 g-height-35 g-font-size-15 g-bg-gray-dark-v2 g-rounded-50x g-mr-10 g-mr-20--md">
                                            <i className="fa fa-clock-o"></i>
                                        </div>
                                        <div className="align-self-center g-letter-spacing-1_5 g-font-weight-700 g-font-size-10 g-font-size-12--md g-color-black">
                                            เวลาทำการ<br />
                                            <strong className="g-color-gray-dark-v2">

                                                จ.-ศ. 08.00-17.30 น.<br />
                                                ส. 08.30-17.30 น.<br />
                                                หยุดวันอาทิตย์ และวันหยุดนักขัตฤกษ์<br />
                                            </strong>
                                        </div>
                                    </div>

                                    <div className="d-flex g-mb-15">
                                        <div className="u-icon-v3 u-icon-size--sm g-width-35 g-height-35 g-font-size-15 g-bg-gray-dark-v2 g-rounded-50x g-mr-10 g-mr-20--md">
                                            <i className="icon-speech"></i>
                                        </div>

                                        <div className="align-self-center g-letter-spacing-1_5 g-font-weight-700 g-font-size-10 g-font-size-12--md g-color-black">
                                            Email:
                                            <a className="g-color-gray-dark-v2 g-color-gray-dark-v2--hover" href="mailto:info@barawindsor.com"><strong>info@barawindsor.com</strong></a>
                                        </div>
                                    </div>

                                    <div className="d-flex g-mb-15">
                                        <div className="u-icon-v3 u-icon-size--sm g-width-35 g-height-35 g-font-size-15 g-bg-gray-dark-v2 g-rounded-50x g-mr-10 g-mr-20--md">
                                            <i className="icon-screen-smartphone"></i>
                                        </div>

                                        <div className="align-self-center g-letter-spacing-1_5 g-font-weight-700 g-font-size-10 g-font-size-12--md g-color-black">
                                            Phone number:
                                            <strong className="g-color-gray-dark-v2">02-095-3222</strong>
                                        </div>
                                    </div>

                                    <div className="d-flex">
                                        <div className="u-icon-v3 u-icon-size--sm g-width-35 g-height-35 g-font-size-15 g-bg-gray-dark-v2 g-rounded-50x g-mr-10 g-mr-20--md">
                                            <i className="fa fa-facebook"></i>
                                        </div>

                                        <div className="align-self-center g-letter-spacing-1_5 g-font-weight-700 g-font-size-10 g-font-size-12--md g-color-black">
                                            Facebook:
                                            <strong className="g-color-gray-dark-v2">
                                                <a className="g-color-gray-dark-v2" target="_blank" href="https://www.facebook.com/toyotabarawindsor/"> Toyota Barawindsor</a>

                                            </strong>
                                        </div>
                                    </div>
                                </address>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="text-center g-color-white g-py-30 g-bg-gray-dark-v2">
                    <div className="container">
                        <p className="g-theme-color-gray-light-v2 mb-0">
                            © 2022 All right reserved.
                            <span className="g-color-gray-dark-v2  " ><strong> </strong></span>.
                            <span className="g-color-white  "  ><strong> BARA, WINDSOR & CO.,LTD.</strong></span>
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )

}

export default Footer;