import React, { useEffect, useState } from "react";
import smileplusMockup from '../../assets/img-web/smileplus-mockup-login.png'
import lineIcon from '../../assets/img/icons/btn_base.png'
import swal from 'sweetalert';
import axios from "axios";
import API from '../../config.json'
import { useNavigate } from "react-router-dom";

import Overlay from "../helper/Overlay";
import { Link } from "react-router-dom";


const LoginMobile = () => {

    document.title = 'เข้าสู่ระบบ';
    const navigate = useNavigate();
    const loggedInUser = localStorage.getItem("userToken");
    if (loggedInUser) {
        navigate("/profile");
    }
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ip, setIP] = useState('');
    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        // console.log(res.data);
        // console.log(res.data.IPv4);
        setIP(res.data.IPv4)
    }
    useEffect(() => {
        //passing getData method to the lifecycle method
        getData()
    }, [])


    const handleSubmit = async (e) => {
        setIsLoading(true)
        e.preventDefault();

        const data = {
            'loginType': 'mobile',
            'username': username,
            'password': password,
            'ip': ip
        }
        // console.log(data);
        await axios.post(`${API.SERVER_URL}/user-login`, data,
            {
                headers: {
                    'Authorization': `Basic ${API.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                console.log(res)
                // console.log(res.data.userInfo)
                setIsLoading(false)
                if (res.data['status']) {
                    swal("เข้าสู่ระบบสำเร็จ", "กรุณารอสักครู่", "success");
                    localStorage.setItem("userTokenExp", Date.now() + 1000);
                    localStorage.setItem("userToken", res.data.userInfo['userID']);
                    localStorage.setItem("fname", res.data.userInfo['userFname']);
                    localStorage.setItem("lname", res.data.userInfo['userLname']);
                    navigate("/profile");
                } else {
                    swal("ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง", "โปรดทำรายการใหม่อีกครั้ง", "warning");
                    setError('ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง');
                }
            })
            .catch(err => {
                console.log(err)
                swal("An error occurred. Please try again later.", "warning");
                setError('An error occurred. Please try again later.');
                setIsLoading(false)
            })
            ;
    }


    return (
        <>
            <Overlay enabled={isLoading} />


            <form className="g-py-15" onSubmit={handleSubmit}>

                <div className="mb-4">
                    <div className="input-group g-brd-primary--focus">
                        <div className="input-group-prepend">
                            <span className="input-group-text g-width-45 g-brd-right-none g-brd-gray-light-v4 g-color-primary"><i className="icon-finance-067 u-line-icon-pro g-pos-rel g-top-2"></i></span>
                        </div>
                        <input
                            className="form-control g-color-black g-brd-left-none g-bg-white g-brd-gray-light-v4 g-pl-0 g-pr-15 g-py-15"
                            type="text"
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                            placeholder="หมายเลขโทรศัพท์มือถือ 10 หลัก"
                            pattern=".{10,}"
                            required />
                    </div>
                </div>

                <div className="mb-4">
                    <div className="input-group g-brd-primary--focus">
                        <div className="input-group-prepend">
                            <span className="input-group-text g-width-45 g-brd-right-none g-brd-gray-light-v4 g-color-primary"><i className="icon-media-094 u-line-icon-pro g-pos-rel g-top-2"></i></span>
                        </div>
                        <input
                            className="form-control g-color-black g-brd-left-none g-bg-white g-brd-gray-light-v4 g-pl-0 g-pr-15 g-py-15"
                            type="password"
                            value={password} onChange={e => setPassword(e.target.value)}
                            placeholder="รหัสผ่าน"
                            required />
                    </div>
                </div>

                <div className="g-mb-50">
                    <button
                        className="btn btn-block g-font-size-14  g-font-weight-600 g-py-16 g-px-25 btn-onesmile "
                        type="submit">เข้าสู่ระบบด้วยหมายเลขโทรศัพท์
                    </button>
                </div>

            </form>



        </>
    );

}

export default LoginMobile;