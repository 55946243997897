import React, { useEffect, useState } from "react";
import validator from 'validator';

import swal from 'sweetalert';
import axios from "axios";
import API from '../../config.json'
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import Overlay from "../helper/Overlay";
import './Style.css'

const SignupForm = (props) => {
    document.title = 'สมัครสมาชิก'
    const navigate = useNavigate();
    // console.log(props)
    // console.log('line-userID' +' '+ props.userId)
    // const phone = localStorage.getItem("phone");
    const [userId, setUserId] = useState(props.userId);
    const [userPhone, setUserPhone] = useState('');
    const [userPhoneError, setUserPhoneError] = useState('');
    // const otp_code = localStorage.getItem("otp_code");
    // const otp_verify = localStorage.getItem("otp_verify");

    const [userEmail, setUserEmail] = useState('');
    const [userEmailError, setUserEmailError] = useState('');
    // const [userPassword, setUserPassword] = useState('');
    const [userFname, setUserFname] = useState('');
    const [userLname, setUserLname] = useState('');
    const [userDateBirth, setUserDateBirth] = useState('');
    // const [userAddress, setUserAddress] = useState('');
    // const [userProvince, setUserProvince] = useState('');
    // const [userDistrict, setUserDistrict] = useState('');
    // const [userSubdistrict, setUserSubdistrict] = useState('');
    // const [userZipcode, setUserZipcode] = useState('');
    const [userChanelRegister, setUserChanelRegister] = useState('');
    const [pdpaCheckbox, setPdpaCheckbox] = useState('');

    const [company, setCompany] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const [formErrors, setFormErrors] = useState({});


    useEffect(() => {
        axios.get(`${API.SERVER_URL}/get-company-group`,
            {
                headers: {
                    'Authorization': `Basic ${API.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                setCompany(res.data)
                // console.log(res)
            })
            .catch(err => {
                console.log(err)

            });

    }, [])



    const handleSubmit = async (e) => {
        e.preventDefault();

        const errors = {};

        if (!validator.isLength(userChanelRegister, { min: 1 })) {
            errors.userChanelRegister = 'โปรดเลือกสาขาที่ท่านสมัคร';
        }


        if (!validator.isLength(userFname, { min: 1 })) {
            errors.userFname = 'โปรดระบุชื่อ';
        }
        if (!validator.isLength(userLname, { min: 1 })) {
            errors.userLname = 'โปรดระบุนามสกุล';
        }
        if (!validator.isLength(userDateBirth, { min: 1 })) {
            errors.userDateBirth = 'โปรดเลือกวันเดือนปีเกิด';
        }

        if (!validator.isLength(userPhone, { min: 10, max: 10 })) {
            errors.userPhone = 'โปรดระบุเบอร์มือถือ 10 หลัก';
        }

        // if (!validator.isEmail(userEmail)) {
        //     errors.userEmail = 'โปรดระบุรูปแบบอีเมลให้ถูกต้อง';
        // }


        if (!validator.isLength(pdpaCheckbox, { min: 1 })) {
            errors.pdpaCheckbox = 'You must agree before submitting.';
        }

        // console.log(errors)
        setFormErrors(errors);
        // return Object.keys(errors).length === 0;

        if (Object.keys(errors).length === 0) {


            setIsLoading(true)
            const data = {
                'userChanelRegister': userChanelRegister,
                'userPhone': userPhone,
                // 'userEmail': userEmail,
                'userFname': userFname,
                'userLname': userLname,
                'userDateBirth': userDateBirth,
                'line_userId': localStorage.getItem('userId'),
                // 'line_email': userEmail,
                'line_displayName': localStorage.getItem('displayName'),
                'line_statusMessage': localStorage.getItem('statusMessage'),
                'line_pictureUrl': localStorage.getItem('pictureUrl'),
            }
            await axios.post(`${API.SERVER_URL}/user-create-line`, data,
                {
                    headers: {
                        'Authorization': `Basic ${API.token}`,
                        'Content-Type': 'application/json',
                    },
                })
                .then(res => {
                    console.log(res)
                    setIsLoading(false)


                    // var userEmail = '';
                    var userPhone = '';
                    // if (res.data.message['userEmail']) {
                    //     userEmail = res.data.message['userEmail'];
                    // }
                    if (res.data.message['userPhone']) {
                        userPhone = res.data.message['userPhone'];
                    }

                    if (res.data.error) {
                        swal('สมัครสมาชิกไม่สำเร็จ', userPhone, "warning")
                        // if (res.data.message['userEmail']) {
                        //     setUserEmailError(res.data.message['userEmail'])
                        // }

                        if (res.data.message['userPhone']) {
                            setUserPhoneError(res.data.message['userPhone'])
                        }

                    } else {
                        swal("ลงทะเบียนสำเร็จ", "", "success");
                        navigate("/login");
                    }
                })
                .catch(err => {
                    setIsLoading(false)
                    console.log(err)
                    // swal("An error occurred. Please try again later.", "warning");
                    setError('An error occurred. Please try again later.');
                });

        }


    }
    return (
        <>
            <Overlay enabled={isLoading} />
            <section className="container g-pt-50 g-pb-20">
                <div className="row justify-content-center">
                    <div className="col-lg-8 order-lg-2 g-mb-10-">
                        <div className="rounded g-px-10 g-py-50- mb-4">
                            <form className="g-py-15 needs-validation" noValidate onSubmit={handleSubmit}  >
                                <div className="row text-center">
                                    <div className="mb-4- col-lg-12 g-py-10">
                                        <h2 className="h2 g-font-weight-600 mb-3 g-font-size-24 g-color-black-onesmile"> สมัครสมาชิก</h2>
                                        <p className="g-color-black-onesmile g-font-size-12">   </p>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <div className="col-12 form-group g-mb-10">
                                                <label htmlFor="fname " className="g-color-black-onesmile g-pl-10 g-font-size-12"> ท่านสมัครผ่านสาขาใด </label>
                                                <select
                                                    name="userChanelRegister"
                                                    onChange={(event) => setUserChanelRegister(event.target.value)}
                                                    value={userChanelRegister}
                                                    className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-20 g-brd-primary--hover rounded g-py-10 g-px-15"
                                                    required >
                                                    <option value="">-กรุณาเลือกสาขาที่คุณสมัคร</option>
                                                    {company.map((row, index) => (
                                                        <option
                                                            value={row.comID}
                                                            key={index}
                                                        >
                                                            {row.comNameT}
                                                        </option>
                                                    ))}
                                                </select>
                                                {formErrors.userChanelRegister && <div className="form-control-feedback">{formErrors.userChanelRegister}</div>}
                                            </div>


                                            <div className="col-6 form-group g-mb-10">
                                                <label htmlFor="fname " className="g-color-black-onesmile g-pl-10 g-font-size-12"> ชื่อ </label>
                                                <input
                                                    className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-20 g-brd-primary--hover rounded g-py-10 g-px-15"
                                                    type="text"
                                                    placeholder=""
                                                    name="userFname"
                                                    required
                                                    value={userFname}
                                                    onChange={e => setUserFname(e.target.value)}

                                                />
                                                {formErrors.userFname && <div className="form-control-feedback">{formErrors.userFname}</div>}

                                            </div>

                                            <div className="col-6 form-group g-mb-10">
                                                <label htmlFor="lname " className="g-color-black-onesmile g-pl-10  g-font-size-12"> นามสกุล</label>
                                                <input className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-20 g-brd-primary--hover rounded g-py-10 g-px-15"
                                                    type="text"
                                                    placeholder=""
                                                    name="userLname"
                                                    required

                                                    value={userLname}
                                                    onChange={e => setUserLname(e.target.value)}

                                                />
                                                {formErrors.userLname && <div className="form-control-feedback">{formErrors.userLname}</div>}
                                            </div>

                                            <div className="col-md-12 form-group g-mb-10">
                                                <label htmlFor="Date of birth " className="g-color-black-onesmile g-pl-10  g-font-size-12"> วัน/เดือน/ปีเกิด</label>
                                                <input className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-20 g-brd-primary--hover rounded g-py-10 g-px-15"
                                                    type="date"
                                                    placeholder=""
                                                    name="userDateBirth"
                                                    required
                                                    value={userDateBirth}
                                                    onChange={e => setUserDateBirth(e.target.value)}
                                                />
                                                {formErrors.userDateBirth && <div className="form-control-feedback">{formErrors.userDateBirth}</div>}
                                            </div>

                                            <div className="col-md-12 form-group g-mb-10 ">
                                                <label className="g-color-black-onesmile g-pl-10  g-font-size-12"> หมายเลขโทรศัพท์ 10 หลัก </label>
                                                <input
                                                    className="
                                                    form-control 
                                                    g-color-black 
                                                    g-bg-white 
                                                    g-bg-white--focus 
                                                    g-brd-gray-light-v3 
                                                    g-rounded-20 
                                                    g-brd-primary--hover
                                                    rounded 
                                                    g-py-10 
                                                    g-px-15
                                                    w-100
                                                    "
                                                    type="number"
                                                    placeholder=""
                                                    name="userPhone"
                                                    value={userPhone}
                                                    onChange={e => setUserPhone(e.target.value)}
                                                    required

                                                />
                                                {formErrors.userPhone && <div className="form-control-feedback">{formErrors.userPhone}</div>}
                                                {userPhoneError && <div className="form-control-feedback">{userPhoneError}</div>}
                                            </div>




                                            <div className="col-md-12 form-group g-mb-40 ju  text-center">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value="1"
                                                    id="invalidCheck"
                                                    name="pdpaCheckbox"
                                                    onChange={e => setPdpaCheckbox(e.target.value)}
                                                    required />
                                                <label className="form-check-label g-font-size-12" htmlFor="invalidCheck">
                                                    ยืนยันและยอมรับเงื่อนไขการลงทะเบียน
                                                    <Link
                                                        to="/privacy"
                                                        className="text-underline g-pl-10"
                                                        target="_blank" >
                                                        รายละเอียดนโยบายความเป็นส่วนตัว
                                                    </Link>
                                                </label>
                                                {formErrors.pdpaCheckbox && <div className="form-control-feedback">{formErrors.pdpaCheckbox}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="mb-5 g-py-20">
                                            <button type="submit" className="btn btn-block g-color-white g-font-size-14 text-uppercase g-py-16 g-px-25 btn-onesmile"> ลงทะเบียน </button>
                                        </div>
                                    </div>
                                </div>

                            </form>




                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default SignupForm;