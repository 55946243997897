import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Menu from "../menu/Menu";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import axios from "axios";
import API from '../../config.json'

import Overlay from "../helper/Overlay";
import './Style.css'


import {
    ThailandAddressTypeahead,
    ThailandAddressValue,
    SubdistrictInput,
    DistrictInput,
    ProvinceInput,
    PostalCodeInput,
} from "react-thailand-address-typeahead";


const ProfileUpdate = () => {
    const navigate = useNavigate();
    const [userToken, setUserToken] = useState(localStorage.getItem("userToken"));

    const [userFname, setUserFname] = useState('');
    const [userLname, setUserLname] = useState('');
    const [userDateBirth, setUserDateBirth] = useState('');
    const [userAddress, setUserAddress] = useState('');
    const [userProvince, setUserProvince] = useState('');
    const [userDistrict, setUserDistrict] = useState('');
    const [userSubdistrict, setUserSubdistrict] = useState('');
    const [userZipcode, setUserZipcode] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    // const customDatasource = {
    //     district: "นาโยง",
    //     postalCode: "92170",
    //     province: "ตรัง",
    //     subdistrict: "นาข้าวเสีย"
    // };

    // const [customDatasource,setCustomDatasource] = useState([]);

    const [val, setVal] = useState(ThailandAddressValue.empty())


    // const [val, setVal] = useState(ThailandAddressValue.empty())

    // const [val, setVal] = React.useState < ThailandAddressValue > (
    //     ThailandAddressValue.fromDatasourceItem({
    //         d: "Khongteoy",
    //         p: "Bangkok",
    //         po: "10110",
    //         s: "Khongteoy",
    //     })
    // );


    useEffect(() => {


        document.title = 'อัพเดทโปรไฟล์';
        const data = {
            'userID': userToken,

        }
        axios.post(`${API.SERVER_URL}/user-info`, data,
            {
                headers: {
                    'Authorization': `Basic ${API.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                // console.log(res.data.userInfo['userFname'])
                // console.log(res.data.userInfo)

                setUserFname(res.data.userInfo['userFname'])
                setUserLname(res.data.userInfo['userLname'])
                setUserDateBirth(res.data.userInfo['userDateBirth'])
                setUserAddress(res.data.userInfo['userAddress'])
                setUserProvince(res.data.userInfo['userProvince'])
                setUserDistrict(res.data.userInfo['userDistrict'])
                setUserSubdistrict(res.data.userInfo['userSubdistrict'])
                setUserZipcode(res.data.userInfo['userZipcode'])


                const customDatasource = {
                    district: res.data.userInfo['userDistrict'],
                    postalCode: res.data.userInfo['userZipcode'],
                    province: res.data.userInfo['userProvince'],
                    subdistrict: res.data.userInfo['userSubdistrict']
                };

                setVal(customDatasource);


            })
            .catch(err => {
                console.log(err)
                swal("An error occurred. Please try again later.", "warning");
                // setError('An error occurred. Please try again later.');
            })
            ;




    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            'userID': userToken,
            'userFname': userFname,
            'userLname': userLname,
            'userDateBirth': userDateBirth,
            'userAddress': userAddress,
            'userProvince': val['province'],
            'userDistrict': val['district'],
            'userSubdistrict': val['subdistrict'],
            'userZipcode': val['postalCode'],
        }

        // console.log(val)
        // console.log(data)
        setIsLoading(true)

        await axios.post(`${API.SERVER_URL}/user-info-update`, data,
            {
                headers: {
                    'Authorization': `Basic ${API.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                console.log(res)

                localStorage.setItem("fname", userFname);
                localStorage.setItem("lname", userLname);

                swal("อัพเดทข้อมูลสำเร็จ", "", "success");
                setIsLoading(false)
                navigate("/profile");

            })
            .catch(err => {
                console.log(err)
                swal("An error occurred. Please try again later.", "warning");
                setError('An error occurred. Please try again later.');
                setIsLoading(false)
            });

    }


    return (
        <>
            <Overlay enabled={isLoading} />
            <section className="container g-pt-50- g-pb-20-">
                <div className="basic address-input-container">
                    <div className="d-none">
                        <br />
                        <code>{JSON.stringify(val)}</code>
                    </div>
                </div>
                <div className="row- justify-content-center-">
                    <div className="col-lg-8-">
                        <div className="row text-center-">
                            <div className="mb-4- col-lg-12 g-py-10">
                                <h2 className="h2 g-font-weight-600 mb-3 g-font-size-20 g-color-black-onesmile" > ข้อมูลส่วนตัว </h2>
                                <p className="g-color-black-onesmile g-font-size-14"  > </p>
                            </div>
                        </div>
                        <form className="g-py-15" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-6 form-group g-mb-10">
                                    <label htmlFor="fname" className="g-font-weight-800 g-pl-10 g-font-size-12"> ชื่อ </label>
                                    <input
                                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-20 g-brd-primary--hover rounded g-py-10 g-px-15"
                                        type="text"
                                        placeholder=""
                                        value={userFname}
                                        onChange={e => setUserFname(e.target.value)}
                                        name="userFname"
                                        required
                                        pattern=".{3,}"
                                        autoComplete="off"
                                    />
                                    <div className="invalid-feedback g-pl-10">Please fill out this field</div>
                                </div>
                                <div className="col-6 form-group g-mb-10">
                                    <label htmlFor="lname " className="g-font-weight-800 g-pl-10 g-font-size-12"> นามสกุล </label>
                                    <input
                                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-20 g-brd-primary--hover rounded g-py-10 g-px-15"
                                        type="text"
                                        placeholder=""
                                        value={userLname}
                                        onChange={e => setUserLname(e.target.value)}
                                        name="userLname"
                                        required
                                        pattern=".{3,}"
                                        autoComplete="off"
                                    />
                                    <div className="invalid-feedback g-pl-10">Please fill out this field</div>
                                </div>

                                <div className="col-md-6 form-group g-mb-10">
                                    <label htmlFor="Date of birth " className="g-font-weight-800 g-pl-10 g-font-size-12">วันเกิด</label>
                                    <input
                                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-20 g-brd-primary--hover rounded g-py-10 g-px-15"
                                        type="date"
                                        placeholder=""
                                        value={userDateBirth}
                                        onChange={e => setUserDateBirth(e.target.value)}
                                        name="userDateBirth"
                                        required
                                        pattern=".{10,}"
                                        autoComplete="off"
                                    />
                                    <div className="invalid-feedback g-pl-10">Please fill out this field</div>
                                </div>
                                <div className="col-md-6 form-group g-mb-10">
                                    <label htmlFor="address " className="g-font-weight-800 g-pl-10 g-font-size-12"> ที่อยู่</label>
                                    <input
                                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-20 g-brd-primary--hover rounded g-py-10 g-px-15"
                                        type="tel"
                                        placeholder=""
                                        value={userAddress}
                                        onChange={e => setUserAddress(e.target.value)}
                                        name="userAddress"
                                        required
                                        pattern=".{3,}"
                                        autoComplete="off"
                                    />
                                    <div className="invalid-feedback g-pl-10">Please fill out this field</div>
                                </div>

                                <hr />
                                <ThailandAddressTypeahead
                                    value={val}
                                    onValueChange={(val) => setVal(val)}
                                >

                                    <ThailandAddressTypeahead.Suggestion
                                        containerProps={{
                                            className: 'suggestion-container'
                                        }}
                                        optionItemProps={{
                                            className: 'suggestion-option'
                                        }}
                                    />


                                    <div className="col-6 form-group g-mb-10">
                                        <label htmlFor="Province " className="g-font-weight-800 g-pl-10 g-font-size-12"> ตำบล / แขวง</label>
                                        <ThailandAddressTypeahead.SubdistrictInput
                                            className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-20 g-brd-primary--hover rounded g-py-10 g-px-15"
                                            containerProps={{
                                                className: 'address-input-field-container'
                                            }}
                                            placeholder="ตำบล / แขวง"

                                        />
                                    </div>

                                    <div className="col-6 form-group g-mb-10">
                                        <label htmlFor="Province " className="g-font-weight-800 g-pl-10 g-font-size-12"> อำเภอ / เขต</label>
                                        <ThailandAddressTypeahead.DistrictInput
                                            className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-20 g-brd-primary--hover rounded g-py-10 g-px-15"
                                            containerProps={{
                                                className: 'address-input-field-container'
                                            }}
                                            placeholder="อำเภอ / เขต"
                                        />
                                    </div>

                                    <div className="col-6 form-group g-mb-10">
                                        <label htmlFor="Province " className="g-font-weight-800 g-pl-10 g-font-size-12"> จังหวัด</label>
                                        <ThailandAddressTypeahead.ProvinceInput
                                            className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-20 g-brd-primary--hover rounded g-py-10 g-px-15"
                                            containerProps={{
                                                className: 'address-input-field-container'
                                            }}
                                            placeholder="จังหวัด"
                                        />
                                    </div>

                                    <div className="col-6 form-group g-mb-10">
                                        <label htmlFor="Province " className="g-font-weight-800 g-pl-10 g-font-size-12"> รหัสไปรษณีย์</label>
                                        <ThailandAddressTypeahead.PostalCodeInput
                                            className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-20 g-brd-primary--hover rounded g-py-10 g-px-15"
                                            containerProps={{
                                                className: 'address-input-field-container'
                                            }}
                                            placeholder="รหัสไปรษณีย์"
                                        />
                                    </div>


                                </ThailandAddressTypeahead>

                            </div>
                            <div className="mb-5">
                                <div className="text-center">
                                    <button type="submit" className="btn u-btn-primary rounded-0 g-py-12 g-px-25" >อัพเดทโปรไฟล์</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </section>

        </>
    )

}

export default ProfileUpdate;