import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import axios from "axios";
import API from '../../config.json'
import SigupForm from './SignupForm';

import { useNavigate } from "react-router-dom";
import Overlay from "../helper/Overlay";

// import liff from '@line/liff';
const liff = window.liff;


const Index = () => {
    document.title = 'เข้าสู่ระบบ';

    const navigate = useNavigate();
    const [pictureUrl, setPictureUrl] = useState('logo');
    const [idToken, setIdToken] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [statusMessage, setStatusMessage] = useState("");
    const [userId, setUserId] = useState("");
    const [error, setError] = useState(false);
    const [userExists, setUserExists] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [ip, setIP] = useState('');
    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP(res.data.IPv4)
    }


    useEffect(() => {
        
        setUserExists(true)
        initLine();
        // getData()
    }, []);

    // const logout = () => {
    //     liff.logout();
    //     window.location.reload();
    // }

    const initLine = () => {
        // onesmileplus.com 1657293895-nbljyKle
        // MANOONPAN-Login 1660820176-JjwoVyxV

        // 1661303525-RXJDJ6z7
        setIsLoading(true)
        liff.init({ liffId: '1661303525-RXJDJ6z7' }, () => {
            if (liff.isLoggedIn()) {
                runApp();
            } else {
                liff.login();
            }
        }, err => console.error(err));
    }

    const runApp = () => {
        const idToken = liff.getIDToken();
        setIdToken(idToken);
        liff.getProfile()
            .then(profile => {
                console.log(profile);
                setDisplayName(profile.displayName);
                setPictureUrl(profile.pictureUrl);
                setStatusMessage(profile.statusMessage);
                localStorage.setItem("userId", profile.userId);
                localStorage.setItem("displayName", profile.displayName);
                localStorage.setItem("pictureUrl", profile.pictureUrl);
                localStorage.setItem("statusMessage", profile.statusMessage);
                setUserId(profile.userId);
                checkLogin(profile.userId);
            })
            .catch(err => console.error(err));
    }


    const checkLogin = (userId) => {
        setUserExists(false)
        const data = {
            'line_userId': userId,
            'ip': ip
        }
        axios.post(`${API.SERVER_URL}/user-login-line`, data,
            {
                headers: {
                    'Authorization': `Basic ${API.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                console.log(res.data)
                if (res.data['status']) {
                    setUserExists(true)
                    localStorage.setItem("userTokenExp", Date.now() + 1000);
                    localStorage.setItem("userToken", res.data.userInfo['userID']);
                    localStorage.setItem("fname", res.data.userInfo['userFname']);
                    localStorage.setItem("lname", res.data.userInfo['userLname']);
                
                    navigate("/profile");
                 } else {
                    setUserExists(false)
                    setIsLoading(false)
                    navigate('/signup-page')
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                swal("An error occurred. Please try again later.", "warning");
                setError('An error occurred. Please try again later.');
            })
            ;
    }

    return (
        <></>
    );
}


export default Index;