import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Menu from "../menu/Menu";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import axios from "axios";
import API from '../../config.json'

import Overlay from "../helper/Overlay";
import './Style.css'




const ProfileChangePassword = () => {
    const navigate = useNavigate();
    const [userToken, setUserToken] = useState(localStorage.getItem("userToken"));

    const [userPassword, setUserPassword] = useState('');
    const [userConPassword, setUserConPassword] = useState('');


    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [bntDisabled, setBtnDisabled] = useState(true)
    // disabled={campDisabled}
    // const [input, setInput] = useState({
    //     username: '',
    //     password: '',
    //     confirmPassword: ''
    // });



    // useEffect(() => {
    //     console.log(userPassword)
    //     console.log(userConPassword)
    // }, [userConPassword, userPassword])


    const validateInput = e => {
        let { name, value } = e.target;

        setError(prev => {
            const stateObj = { ...prev, [name]: "" };

            switch (name) {
                case "username":
                    if (!value) {
                        stateObj[name] = "Please enter Username.";
                    }
                    break;

                case "userPassword":


                    if (!value) {
                        stateObj[name] = "Please enter Password.";
                        setBtnDisabled(true);
                    } else if (userConPassword && value !== userConPassword) {
                        stateObj["userConPassword"] = "Password and Confirm Password does not match.";
                        setBtnDisabled(true);
                    } else if (userConPassword && value == userConPassword) {
                        setBtnDisabled(false);
                    }
                    else {
                        stateObj["userConPassword"] = userConPassword ? "" : error.userConPassword;
                    }

                    break;

                case "userConPassword":

                    if (!value) {
                        stateObj[name] = "Please enter Confirm Password.";
                        setBtnDisabled(true);
                    } else if (userPassword && value !== userPassword) {
                        stateObj[name] = "Password and Confirm Password does not match.";
                        setBtnDisabled(true);
                    } else if (userPassword && value == userPassword) {
                        setBtnDisabled(false);
                    }

                    break;

                default:
                    break;
            }

            return stateObj;
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            'userID': userToken,
            'userPassword': userPassword,

        }

        // console.log(val)
        console.log(data)

        setIsLoading(true)

        await axios.post(`${API.SERVER_URL}/user-changepassword`, data,
            {
                headers: {
                    'Authorization': `Basic ${API.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                console.log(res)

                swal("อัพเดทข้อมูลสำเร็จ", "", "success");
                setUserPassword('')
                setUserConPassword('')
                setIsLoading(false)
                navigate("/profile");
            })
            .catch(err => {
                console.log(err)
                swal("An error occurred. Please try again later.", "warning");
                setError('An error occurred. Please try again later.');
                setIsLoading(false)
            });
    }

    return (
        <>
            <Overlay enabled={isLoading} />
            <section className="container g-pt-50- g-pb-20-">
                <div className="row- justify-content-center-">
                    <div className="col-lg-8-">
                        <div className="row text-center-">
                            <div className="mb-4- col-lg-12 g-py-10">
                                <h2
                                    className="h2 g-font-weight-600 mb-3 g-font-size-20 g-color-black-onesmile" >
                                    อัพเดทความปลอดภัย </h2>
                                <p className="g-color-black-onesmile g-font-size-14"  > </p>
                            </div>
                        </div>
                        <form className="g-py-15" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12 form-group g-mb-10">
                                    <label htmlFor="fname" className="g-font-weight-800 g-pl-10 g-font-size-12"> รหัสผ่านใหม่ </label>
                                    <input
                                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-20 g-brd-primary--hover rounded g-py-10 g-px-15"
                                        type="text"
                                        placeholder="รหัสผ่านใหม่อย่างน้อย 6 ตัวอักษร"
                                        value={userPassword}
                                        onChange={e => setUserPassword(e.target.value)}
                                        name="userPassword"
                                        onBlur={validateInput}
                                        required
                                        pattern=".{6,}"
                                        autoComplete="off"
                                    />
                                    {error.userPassword && <span className='text-err g-pl-10'>{error.userPassword}</span>}
                                </div>
                                <div className="col-12 form-group g-mb-10">
                                    <label htmlFor="lname " className="g-font-weight-800 g-pl-10 g-font-size-12"> ยืนยันรหัสผ่านใหม่ </label>
                                    <input
                                        className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-20 g-brd-primary--hover rounded g-py-10 g-px-15"
                                        type="text"
                                        placeholder="ยืนยันรหัสผ่านใหม่"
                                        value={userConPassword}
                                        onChange={e => setUserConPassword(e.target.value)}
                                        onBlur={validateInput}
                                        name="userConPassword"
                                        required
                                        pattern=".{6,}"
                                        autoComplete="off"
                                    />
                                    {error.userConPassword && <span className='text-err g-pl-10'>{error.userConPassword}</span>}
                                </div>
                            </div>
                            <div className="mb-5">
                                <div className="text-center">
                                    <button
                                        type="submit"
                                        className="btn u-btn-primary rounded-0 g-py-12 g-px-25"
                                        disabled={bntDisabled}
                                    >
                                        อัพเดทความปลอดภัย</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </section>

        </>
    )

}

export default ProfileChangePassword;