import React, { useEffect, useState } from "react";
import swal from 'sweetalert';
import axios from "axios";
import API from '../../config.json'
import { useNavigate } from "react-router-dom";


import Overlay from "../helper/Overlay";


const SigupForm = () => {
    document.title = 'สมัครสมาชิก'
    const navigate = useNavigate();



    const phone = localStorage.getItem("phone");
    const [userPhoneError, setUserPhoneError] = useState('');
    const otp_code = localStorage.getItem("otp_code");
    const otp_verify = localStorage.getItem("otp_verify");

    const [userEmail, setUserEmail] = useState('');
    const [userEmailError, setUserEmailError] = useState('');
    // const [userPassword, setUserPassword] = useState('');
    const [userFname, setUserFname] = useState('');
    const [userLname, setUserLname] = useState('');
    const [userDateBirth, setUserDateBirth] = useState('');
    const [userAddress, setUserAddress] = useState('');
    const [userProvince, setUserProvince] = useState('');
    const [userDistrict, setUserDistrict] = useState('');
    const [userSubdistrict, setUserSubdistrict] = useState('');
    const [userZipcode, setUserZipcode] = useState('');
    const [userChanelRegister, setUserChanelRegister] = useState('');

    const [company, setCompany] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {

        axios.get(`${API.SERVER_URL}/get-company-group`,
            {
                headers: {
                    'Authorization': `Basic ${API.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                setCompany(res.data)
                // console.log(res)
            })
            .catch(err => {
                console.log(err)

            });

    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)

        const data = {
            'userChanelRegister': userChanelRegister,
            'userPhone': phone,
            'userEmail': userEmail,
            'userFname': userFname,
            'userLname': userLname,
            'userDateBirth': userDateBirth,

        }

        // console.log(data)


        await axios.post(`${API.SERVER_URL}/user-create`, data,
            {
                headers: {
                    'Authorization': `Basic ${API.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                console.log(res)
                setIsLoading(false)
                
                var userEmail   = '';
                var userPhone = '';
                if(res.data.message['userEmail']){
                    userEmail = res.data.message['userEmail'];
                }
               
                if(res.data.message['userPhone']){
                     userPhone = res.data.message['userPhone'];
                }

                if (res.data.error) {
                    swal('สมัครสมาชิกไม่สำเร็จ', userEmail + ', ' + userPhone, "warning")

                    if (res.data.message['userEmail']) {
                        setUserEmailError(res.data.message['userEmail'])
                    }

                    if (res.data.message['userPhone']) {
                        setUserPhoneError(res.data.message['userPhone'])
                    }

                } else {
                    swal("ลงทะเบียนสำเร็จ", "", "success");
                    navigate("/login");
                }



            })
            .catch(err => {
                setIsLoading(false)
                console.log(err)
                swal("An error occurred. Please try again later.", "warning");
                setError('An error occurred. Please try again later.');
            });





    }
    return (
        <>
            <Overlay enabled={isLoading} />
            <section className="container g-pt-50 g-pb-20">
                <div className="row justify-content-center">
                    <div className="col-lg-8 order-lg-2 g-mb-10-">
                        <div className="rounded g-px-10 g-py-50- mb-4">

                            <form className="g-py-15" onSubmit={handleSubmit}>

                                <div className="row text-center">
                                    <div className="mb-4- col-lg-12 g-py-10">
                                        <h2 className="h2 g-font-weight-600 mb-3 g-font-size-24 g-color-black-onesmile"> สมัครสมาชิก</h2>
                                        <p className="g-color-black-onesmile g-font-size-12">   </p>
                                    </div>
                                </div>

                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="row">


                                            <div className="col-12 form-group g-mb-10">
                                                <label htmlFor="fname " className="g-color-black-onesmile g-pl-10 g-font-size-12"> ท่านสมัครผ่านสาขาใด </label>
                                                <select
                                                    name="userChanelRegister"
                                                    onChange={(event) => setUserChanelRegister(event.target.value)}
                                                    value={userChanelRegister}
                                                    className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-20 g-brd-primary--hover rounded g-py-10 g-px-15"
                                                    required >
                                                    <option value="">-กรุณาเลือกสาขาที่คุณสมัคร</option>
                                                    {company.map((row, index) => (
                                                        <option
                                                            value={row.comID}
                                                            key={index}
                                                        >
                                                            {row.comNameT}
                                                        </option>
                                                    ))}

                                                </select>
                                                <div className="invalid-feedback g-pl-10"> Please select</div>
                                            </div>


                                            <div className="col-6 form-group g-mb-10">
                                                <label htmlFor="fname " className="g-color-black-onesmile g-pl-10 g-font-size-12"> ชื่อ </label>
                                                <input
                                                    className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-20 g-brd-primary--hover rounded g-py-10 g-px-15"
                                                    type="text"
                                                    placeholder=""
                                                    name="userFname"
                                                    required pattern=".{3,}"
                                                    value={userFname}
                                                    onChange={e => setUserFname(e.target.value)}

                                                />

                                            </div>

                                            <div className="col-6 form-group g-mb-10">
                                                <label htmlFor="lname " className="g-color-black-onesmile g-pl-10  g-font-size-12"> นามสกุล</label>
                                                <input className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-20 g-brd-primary--hover rounded g-py-10 g-px-15"
                                                    type="text"
                                                    placeholder=""
                                                    name="userLname"
                                                    required
                                                    pattern=".{3,}"
                                                    value={userLname}
                                                    onChange={e => setUserLname(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-md-12 form-group g-mb-10">
                                                <label htmlFor="Date of birth " className="g-color-black-onesmile g-pl-10  g-font-size-12"> วัน/เดือน/ปีเกิด</label>
                                                <input className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-20 g-brd-primary--hover rounded g-py-10 g-px-15"
                                                    type="date"
                                                    placeholder=""
                                                    name="userDateBirth"
                                                    required
                                                    pattern=".{10,}"
                                                    value={userDateBirth}
                                                    onChange={e => setUserDateBirth(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-md-12 form-group g-mb-10 u-has-error-v1">
                                                <label htmlFor="email " className="g-color-black-onesmile g-pl-10  g-font-size-12"> หมายเลขโทรศัพท์ 10 หลัก </label>
                                                <input
                                                    className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-20 g-brd-primary--hover rounded g-py-10 g-px-15"
                                                    type="text"
                                                    placeholder=""
                                                    name="userPhone"
                                                    value={phone}
                                                    required
                                                    pattern="[0-9]{10}"
                                                    readOnly
                                                />
                                                <small className="form-control-feedback g-pl-10 "> {userPhoneError}</small>
                                            </div>

                                            <div className="col-md-12 form-group g-mb-10 u-has-error-v1">
                                                <label htmlFor="email " className="g-color-black-onesmile g-pl-10  g-font-size-12">อีเมล</label>
                                                <input
                                                    className="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-20 g-brd-primary--hover rounded g-py-10 g-px-15"
                                                    type="email"
                                                    placeholder=""
                                                    name="userEmail"
                                                    required
                                                    pattern="[a-z-A-Z-0-9._%+-]+@[a-z-A-Z-0-9.-]+\.[a-z-A-Z]{2,4}$"
                                                    value={userEmail}
                                                    onChange={e => setUserEmail(e.target.value)}
                                                />
                                                <small className="form-control-feedback g-pl-10 "> {userEmailError}</small>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <div className="mb-5 g-py-20">
                                    <button type="submit" className="btn btn-block g-color-white g-font-size-14 text-uppercase g-py-16 g-px-25 btn-onesmile"> ลงทะเบียน </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default SigupForm;