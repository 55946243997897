import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import API from '../../config.json'
import swal from 'sweetalert';
import Menu from "../menu/Menu";
import Preload from "./Preload";

const liff = window.liff;



const Promotion = () => {
    // const [userToken, setUserToken] = useState(localStorage.getItem("userToken"));
    // const [userToken, setUserToken] = useState(localStorage.getItem("userToken"));
    const [userToken, setUserToken] = useState();
    const [campaign, setCampagin] = useState([]);
    const [Loading, setLoading] = useState(true);

    const [isLoading, setIsLoading] = useState(false);
    const [idToken, setIdToken] = useState("");
    const [userExists, setUserExists] = useState(true);
    const [error, setError] = useState(false);


    useEffect(() => {
        document.title = 'โปรโมชั่น';

        initLine()


    }, [])


    /////////////////// LINE Liff
    const initLine = () => {
        // onesmileplus.com 1657293895-nbljyKle
        // MANOONPAN-Login 1660820176-JjwoVyxV
        // checkLogin('U8880ed9889d756222c84a63ae6993cb6')

        setIsLoading(true)

        liff.init({ liffId: '1661303525-VGPbP48M' }, () => {
            if (liff.isLoggedIn()) {
                runApp();
            } else {
                liff.login();
            }
        }, err => console.error(err));
    }

    const runApp = () => {
        const idToken = liff.getIDToken();
        setIdToken(idToken);

        liff.getProfile()
            .then(profile => {
                console.log(profile);
                checkLogin(profile.userId);
            })
            .catch(err => console.error(err));
    }

    const checkLogin = (userId) => {

        console.log(userId)

        setUserExists(false)
        setUserExists(true)

        const data = {
            'line_userId': userId
            // 'ip': ip
        }
        // console.log(data);
        axios.post(`${API.SERVER_URL}/user-login-line`, data,
            {
                headers: {
                    'Authorization': `Basic ${API.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                // console.log(res.data)
                console.log(res.data.userInfo)
                if (res.data['status']) {
                    setUserExists(true)
                    setUserToken(res.data.userInfo['userID']);
                    localStorage.setItem("userTokenExp", Date.now() + 1000);
                    localStorage.setItem("userToken", res.data.userInfo['userID']);
                    localStorage.setItem("fname", res.data.userInfo['userFname']);
                    localStorage.setItem("lname", res.data.userInfo['userLname']);
                    setIsLoading(false)

                    if (res.data.userInfo['userID']) {
                        get_campagin(res.data.userInfo['userID'])
                    }

                } else {
                    setUserExists(false)
                    setIsLoading(false)
                    Navigate("/signup-page");
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                swal("An error occurred. Please try again later.", "warning");
                setError('An error occurred. Please try again later.');
            })
            ;
    }


    const get_campagin = (userID) => {
        const data = {
            'userID': userID
        }

        axios.post(`${API.SERVER_URL}/get-campaign`, data,
            {
                headers: {
                    'Authorization': `Basic ${API.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                console.log(res)
                setCampagin(res.data)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                swal(err, "warning");

            });

    }



    return (
        <>
            <Menu />
            <section>


                <div className="container g-pt-50 g-pb-70">
                    <header className="text-center g-width-60x--md mx-auto g-mb-20 d-none">
                        <div className="u-heading-v2-3--bottom g-brd-primary g-mb-5">
                            <h2 className="h4 u-heading-v2__title g-color-gray-dark-v2 text-uppercase g-font-weight-600">
                                โปรโมชั่นพิเศษสำหรับสมาชิก
                            </h2>
                        </div>
                    </header>

                    {Loading &&
                        <div className="g-pt-20 g-mb-10">
                            <Preload />
                        </div>
                    }


                    {campaign && (
                        <div className="row  ">
                            {campaign.map((item, key) => (

                                <div className="col-lg-6 col-xs-12 g-pt-20 g-mb-10" key={key}>
                                    <Link
                                        key={item.campID}
                                        to={
                                            {
                                                pathname: `/promotion-detail/${item.campID}`,
                                                state: item.campID
                                            }
                                        }>
                                        <article className="u-block-hover u-block-hover--uncroped  ">
                                            <div className="d-block u-block-hover__additional--jump g-mb-10 g-rounded-20  " >
                                                <img className="w-100" src={item.campPic_url + '/' + item.campPic} loading="lazy" />
                                            </div>
                                            {/* <em className="d-block g-color-gray-dark-v5 g-font-style-normal g-font-size-12 g-mb-0 d-none">2013</em> */}
                                            <div className="js-rating g-color-primary g-font-size-11 g-mb-10" data-rating="3.5" data-spacing="5"></div>
                                            <h3 className="h5 d-none">
                                                <div className="g-color-main g-color-primary--hover g-text-underline--none--hover" >
                                                    {item.campNameTH}
                                                </div>
                                            </h3>
                                        </article>
                                    </Link>

                                </div>

                            ))}
                        </div>

                    )}


                </div>
            </section>

        </>
    );

}

export default Promotion;