import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import ReactGA from 'react-ga4';




import Notfound from "./component/notfound/Notfound";
import Homepage from './component/homepage/Homepage';
import LoginMain from "./component/login/_LoginMain";
import LoginEmail from "./component/login/LoginEmail";
import LoginMobile from "./component/login/LoginMobile";
import Logout from "./component/logout/Logout";
import Forgotpassword from "./component/forgotpassword/Forgotpassword";
// import Privacy from "./component/privacy/Provacy";
import Privacy from "./component/privacy/Privacy";


import Profile from "./component/profile/Profile";
import ProfileSetting from "./component/profile/ProfileSetting";
import ProfileUpdate from "./component/profile/ProfileUpdate";
import ProfileLineConnect from "./component/profile/ProfileLineConnect";
import ProfileChangePassword from "./component/profile/ProfileChangePassword";
import Promotion from "./component/promotion/Promotion";
import PromotionDetail from "./component/promotion/PromotionDeail";

import Home from "./component/home/Home";
import MyCoupon from "./component/mycoupon/MyCoupon";
import MyCouponView from "./component/mycoupon/MyCouponView";

import Signup from "./component/signup/Signup";
import SignupOTP from "./component/signup/SignupOTP";
import SigupForm from "./component/signup/SignupForm";
import SignupLine from "./component/signup/SignupLine";
import LineLiffLogin from "./component/line/LineLiffLogin";
import MenuBtm from "./component/menu/MenuBtm";


import Index from "./component/LineAuthen/Index";
import SignupForm from "./component/LineAuthen/SignupForm";

import CookieConsent, { Cookies } from "react-cookie-consent";

import { Link } from "react-router-dom";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const TRACKING_ID = 'G-PPZXRPMJHC';
  ReactGA.initialize(TRACKING_ID);
  // ReactGA.send(window.location.pathname + window.location.search);
  ReactGA.send(document.location.pathname);

  useEffect(() => {

    // ReactGA.pageview(window.location.pathname + window.location.search);

    const loggedInUser = localStorage.getItem("userToken");
    if (loggedInUser) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false)
    }

  }, []);



  return (
    <Router >

      <Routes>
        <Route path='*' element={<Notfound />} />
        <Route path="/" exact element={<Homepage />} />
        <Route path="/login" element={<LoginMain />} />
        <Route path="/login-email" element={<LoginEmail />} />
        <Route path="/login-mobile" element={<LoginMobile />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/forgot-password" element={<Forgotpassword />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile-setting" element={<ProfileSetting />} />
        <Route path="/profile-view" element={<ProfileUpdate />} />
        <Route path="/profile-lineconnect" element={<ProfileLineConnect />} />


        {/* Line API V2 */}
        <Route path="/line" element={<Index />} />
        <Route path="/signup-page" element={<SignupForm />} />

        <Route path="/profile-changepassword" element={<ProfileChangePassword />} />
        <Route path="/promotion" element={<Promotion />} />
        <Route path="/promotion-detail/:campID" element={<PromotionDetail />} />
        <Route path="/my-coupon" element={<MyCoupon />} />
        <Route path="/my-coupon-view/:dtKey/:campID" element={<MyCouponView />} />
        <Route path="/home" element={<Home />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
        {/* <Route path="/signup" element={<LineLiffLogin />} /> */}

        <Route path="/signup-otp" element={<SignupOTP />} />
        <Route path="/signup-form" element={<SigupForm />} />
        <Route path="/signup-line" element={<SignupLine />} />
        <Route path="/line-liff" element={<LineLiffLogin />} />
        <Route path="/privacy" element={<Privacy />} />





      </Routes>

      {/* {isLoggedIn && <MenuBtm />} */}


      <CookieConsent style={{ fontSize: "10px", color: "white" }}>เราใช้คุกกี้ cookie เพื่อสำรวจหน้าเว็บไซต์และสามารถวิเคราะห์การใช้งานของเว็บไซต์ให้มีประสิทธิภาพยิ่งขึ้น เพื่อส่งมอบการบริการที่ดีที่สุดให้แก่ท่าน
        <Link to="/privacy" target="_blank"><span style={{ fontSize: "10px", color: "white" }}>  นโยบายความเป็นส่วนตัว</span></Link>
      </CookieConsent>

    </Router>



  );
}

export default App;
