import React from "react";
import Logo from '../../assets/img/logo/logo.png'

import { Link } from "react-router-dom";

import ProfileUpdate from "./ProfileUpdate";
import ProfileChangePassord from "./ProfileChangePassword";


const ProfileSetting = () => {

    return (
        <>
            <div className="container g-py-20">
                <ul
                    className="nav nav-justified u-nav-v1-1 u-nav-primary g-brd-bottom--md g-brd-bottom-2 g-brd-primary g-mb-20"
                    role="tablist"
                    data-target="nav-1-1-default-hor-left-underline"
                    // data-tabs-mobile-type="slide-up-down"
                    data-btn-classes="btn btn-md btn-block rounded-0 u-btn-outline-primary g-mb-20" >

                    <li className="nav-item">
                        <a
                            className="nav-link g-py-10 show active"
                            data-toggle="tab"
                            href="#nav-1-1-default-hor-left-underline--1"
                            role="tab"
                            aria-selected="true"
                        >อัพเดทโปรไฟล์</a>
                    </li>
                    <li className="nav-item">
                        <a
                            className="nav-link g-py-10 show"
                            data-toggle="tab"
                            href="#nav-1-1-default-hor-left-underline--2"
                            role="tab"
                            aria-selected="false"
                        >อัพเดทความปลอดภัย</a>
                    </li>

                </ul>

                <div id="nav-1-1-default-hor-left-underline" className="tab-content">
                    <div className="tab-pane fade active show" id="nav-1-1-default-hor-left-underline--1" role="tabpanel" data-parent="#nav-1-1-default-hor-left-underline">
                        <ProfileUpdate />
                    </div>
                    <div className="tab-pane fade"
                        id="nav-1-1-default-hor-left-underline--2" role="tabpanel"
                        data-parent="#nav-1-1-default-hor-left-underline">
                        <ProfileChangePassord />
                    </div>
                </div>
            </div>

        </>
    );
}

export default ProfileSetting;