import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import axios from "axios";
import API from '../../config.json'
import SigupForm from './SignupForm';

import { useNavigate } from "react-router-dom";
import Overlay from "../helper/Overlay";



// import liff from '@line/liff';
const liff = window.liff;

function LineLiffLogin() {
    document.title = 'เข้าสู่ระบบ';

    const navigate = useNavigate();
    const [pictureUrl, setPictureUrl] = useState('logo');
    const [idToken, setIdToken] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [statusMessage, setStatusMessage] = useState("");
    const [userId, setUserId] = useState("");
    const [error, setError] = useState(false);
    const [userExists, setUserExists] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [ip, setIP] = useState('');
    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        // console.log(res.data);
        // console.log(res.data.IPv4);
        setIP(res.data.IPv4)
    }


    useEffect(() => {
        console.log('ss')
        setUserExists(true)
        // initLine();
        // getData()
    }, []);

    const logout = () => {
        liff.logout();
        window.location.reload();
    }

    const initLine = () => {

        // onesmileplus.com 1657293895-nbljyKle
        // MANOONPAN-Login 1660820176-JjwoVyxV
        
        setIsLoading(true)
        liff.init({ liffId: '1661303525-RXJDJ6z7' }, () => {
            if (liff.isLoggedIn()) {
                runApp();
            } else {
                liff.login();
            }
        }, err => console.error(err));
    }

    const runApp = () => {
        const idToken = liff.getIDToken();
        setIdToken(idToken);

        liff.getProfile()
            .then(profile => {
                // console.log(profile);

                setDisplayName(profile.displayName);
                setPictureUrl(profile.pictureUrl);
                setStatusMessage(profile.statusMessage);
                setUserId(profile.userId);

                checkLogin(profile.userId);

            })
            .catch(err => console.error(err));
    }




    const checkLogin = (userId) => {

        setUserExists(false)
        setUserExists(true)

        const data = {
            'line_userId': userId,
            'ip': ip
        }

        // console.log(data);


        axios.post(`${API.SERVER_URL}/user-login-line`, data,
            {
                headers: {
                    'Authorization': `Basic ${API.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                // console.log(res.data)
                // console.log(res.data.userInfo)

                if (res.data['status']) {
                    setUserExists(true)
                    // swal("Successfully Login.", "Plase wait....", "success");
                    localStorage.setItem("userTokenExp", Date.now() + 1000);
                    localStorage.setItem("userToken", res.data.userInfo['userID']);
                    localStorage.setItem("fname", res.data.userInfo['userFname']);
                    localStorage.setItem("lname", res.data.userInfo['userLname']);
                    // window.location.href = '/profile';
                    // navigate("/profile");

                } else {

                    setUserExists(false)
                    setIsLoading(false)
                    console.log('Invalid username or password')
                    // swal("Invalid username or password", "Plase tray gain.", "warning");
                    // setError('Invalid username or password');
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                swal("An error occurred. Please try again later.", "warning");
                setError('An error occurred. Please try again later.');
            })
            ;
    }

    return (

        <>

            <Overlay enabled={isLoading} />


            <div className="App">
                {!userExists && <SigupForm idToken={idToken} userId={userId} displayName={displayName} pictureUrl={pictureUrl} statusMessage={statusMessage} />}
            </div>


            {/* {!userExists ? <SigupForm
                idToken={idToken}
                userId={userId}
                displayName={displayName}
                pictureUrl={pictureUrl}
                statusMessage={statusMessage} />  } */}
            {/* 
            // <div className="App d-none">
            //     <header className="App-header">
            //         <div style={{ textAlign: "center" }}>
            //             <h1>React with LINE Login test bot1</h1>
            //             <hr />
            //             <img src={pictureUrl} width="300px" height="300px" />
            //             <p style={{ textAlign: "left", marginLeft: "20%", marginRight: "20%", wordBreak: "break-all" }}><b>id token: </b> {idToken}</p>
            //             <p style={{ textAlign: "left", marginLeft: "20%", marginRight: "20%", wordBreak: "break-all" }}><b>display name: </b> {displayName}</p>
            //             <p style={{ textAlign: "left", marginLeft: "20%", marginRight: "20%", wordBreak: "break-all" }}><b>status message: </b> {statusMessage}</p>
            //             <p style={{ textAlign: "left", marginLeft: "20%", marginRight: "20%", wordBreak: "break-all" }}><b>user id: </b> {userId}</p>

            //             <button onClick={() => logout()} style={{ width: "100%", height: 30 }}>Logout</button>
            //         </div>
            //     </header>
            // </div> */}
        </>

    );
}

export default LineLiffLogin;