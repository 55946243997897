import React, { useEffect, useState } from "react";

import './Style.css'

const SignupOTP = () => {

    const phone = localStorage.getItem("phone");
    const otp_code = localStorage.getItem("otp_code");
    const otp_verify = localStorage.getItem("otp_verify");

    const [userOTP, setUserOTP] = useState('');
    const [key1, setkey1] = useState('')
    const [key2, setkey2] = useState('')
    const [key3, setkey3] = useState('')
    const [key4, setkey4] = useState('')



    useEffect(() => {
        // console.log(phone)
        // console.log(otp_code)
        // console.log(otp_verify)
        // console.log(key1)
        // console.log(key2)

        // console.log('otp ')
    }, [])



    const getCodeBoxElement = (index) => {
        return document.getElementById('codeBox' + index);
    }


    const onKeyUpEvent = async (index, e) => {
        // const eventCode = e.which || e.keyCode;
        // console.log(index)
        // console.log(e.target.name)
        // [e.target.name] = e.target.value;
        // console.log(index)
        const eventCode = e.which || e.keyCode;
        const { name, value } = e.target;


        if (getCodeBoxElement(index).value.length === 1) {
            if (index !== 4) {
                getCodeBoxElement(index + 1).focus();
            } else {
                const otp = key1 + '' + key2 + '' + key3 + '' + key4;
                // // setUserOTP(key1 + '' + key2 + '' + key3 + '' + key4)
                console.log(otp)
                if (otp_verify == otp) {
                    // alert('ok')

                    window.location.href='/signup-form';
                } else {
                    alert('no')
                }
                getCodeBoxElement(index).blur();
            
            }
        }
        if (eventCode === 8 && index !== 1) {
            getCodeBoxElement(index - 1).focus();
        }

 
    }



    return (
        <>

            <section className="container g-pt-50 g-pb-20">
                <div className="row justify-content-center">
                    <div className="col-lg-8 order-lg-2 g-mb-10-">
                        <div className="rounded g-px-10 g-py-50- mb-4">
                            <div className="row text-center">
                                <div className="mb-4- col-lg-12 g-py-10">
                                    <h2 className="h2 g-font-weight-600 mb-3 g-font-size-24 g-color-black-onesmile" >โปรดกรอก </h2>
                                    <p className="g-color-black-onesmile g-font-size-12"  > OTP ถูกส่งไป</p>
                                </div>
                            </div>

                            <div className="mb-4 g-font-size-14 text-center" >
                                <div className="row text-center">
                                    <div className="mb-4- col-lg-12 g-py-10">
                                        <p className="g-color-black-onesmile g-font-size-12"  >โปรดกรอก OTP </p>
                                        <p className="g-color-black-onesmile g-font-size-16"  >OTP</p>
                                    </div>
                                </div>
                                <input
                                    className="g-rounded-10"
                                    id="codeBox1"
                                    type="number"
                                    maxLength=""
                                    name="inp1"
                                    value={key1}
                                    onChange={e => setkey1(e.target.value)}
                                    onKeyUp={(e) => onKeyUpEvent(1, e)}

                                />
                                <input
                                    className="g-rounded-10"
                                    id="codeBox2"
                                    type="number"
                                    maxLength="1"
                                    name="inp2"
                                    value={key2}
                                    onChange={e => setkey2(e.target.value)}
                                    onKeyUp={(e) => onKeyUpEvent(2, e)}
                                />
                                <input
                                    className="g-rounded-10"
                                    id="codeBox3"
                                    type="number"
                                    maxLength="1"
                                    name="inp3"
                                    value={key3}
                                    onChange={e => setkey3(e.target.value)}
                                    onKeyUp={(e) => onKeyUpEvent(3, e)}

                                />
                                <input
                                    className="g-rounded-10"
                                    id="codeBox4"
                                    type="number"
                                    maxLength="1"
                                    name="inp4"
                                    value={key4}
                                    onChange={e => setkey4(e.target.value)}
                                    onKeyUp={(e) => onKeyUpEvent(4, e)}
                                />

                            </div>


                            <div className="row justify-content-between mb-5">
                                <div className="col align-self-center">
                                </div>
                            </div>


                            <div className="mb-5">
                                <button className="btn btn-otp btn-block g-color-white g-font-size-14 text-uppercase g-py-16 g-px-25 btn-onesmile">ยืนยัน</button>
                            </div>
                        </div>



                    </div>
                </div>
            </section>
        </>
    );

}

export default SignupOTP;