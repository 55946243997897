import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom"
import parse from 'html-react-parser';

import moment from "moment";

import Menu from "../menu/Menu";
import axios from "axios";
import API from '../../config.json'

// import QRCode from 'react-qr-code';
// import Barcode from 'react-barcode';

import GenBarcode from "../libraries/GenBarcode";
import GenQRCode from "../libraries/GenQRCode";
import Overlay from "../helper/Overlay";



const MyCouponView = () => {

    const [isLoading, setIsLoading] = useState(true);
    const params = useParams()
    const [dtKey, setDtKey] = useState(params.dtKey)
    const [campID, setCampID] = useState(params.campID)

    const [userToken, setUserToken] = useState(localStorage.getItem("userToken"));

    const [myCoupon, setMyCoupon] = useState([])
    const [campPic, setCampPic] = useState(null);
    const [campNameTH, setCampNameTH] = useState(null);
    const [campDetailTH, setCampDetailTH] = useState(<p></p>);

    const [dtCode, setDtCode] = useState('');
    const [dtEndDate, setDtEndDate] = useState(null);
    const [dtStatus, setDtStatus] = useState(null);

    const [campStartDate, setCampStartDate] = useState(null);
    const [campEndDate, setCampEndDate] = useState(null);
    const [campPerUser, setCampPerUser] = useState(null);
    const [campAmount, setCampAmount] = useState(null);




    useEffect(() => {

        const data = {
            'userID': userToken,
            'campID': campID,
            'dtKey': dtKey,
        }

        axios.post(`${API.SERVER_URL}/get-campaign-redeemDT`, data,
            {
                headers: {
                    'Authorization': `Basic ${API.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                // console.log(res.data.campInfo)
                // setMyCoupon(res.data.campInfo)

                setDtCode(res.data.campInfo['dtCode'])
                setDtStatus(res.data.campInfo['dtStatus'])
                setDtEndDate(res.data.campInfo['dtEndDate'])

                setCampPic(res.data.campInfo['campPic_url'] + '/' + res.data.campInfo['campPic'])
                setCampNameTH(res.data.campInfo['campNameTH'])
                setCampDetailTH(res.data.campInfo['campDetailTH'])
                setCampStartDate(res.data.campInfo['campStartDate'])
                setCampEndDate(res.data.campInfo['campEndDate'])
                setIsLoading(false)

            })
            .catch(err => {
                setIsLoading(false)
                console.log(err)
            });



    }, [])


    return (
        <>
            <Overlay enabled={isLoading} />
            <Menu />
            <section>
                <div className="container g-pt-50 g-pb-70">

                    <div className="shortcode-html">
                        <div className="row">
                            <div className="col-lg-6 g-rounded-20">
                                <img className="img-fluid w-100 g-rounded-20" src={campPic} alt={campNameTH} />
                            </div>

                            <div className="col-lg-6">
                                <div className="g-px-40--lg -g-py-70">

                                    {moment(campStartDate).format("MMM DD YYYY")} - {moment(campEndDate).format("MMM DD YYYY")}

                                    <div className="g-mb-10">
                                        <h2 className="g-font-weight-800 mb--4"> {campNameTH}</h2>
                                        <div className="g-pa-25">
                                            <div className="g-mb-30 col-lg-12 g-px-25 text-center justify-content-center">
                                                <div className="g-mb-30">
                                                    {dtStatus === 'EX' &&
                                                        <span className="u-label u-label--sm g-bg-lightred g-rounded-20 g-px-10 g-font-size-13"> โค้ดหมดอายุการใช้งาน </span>
                                                    }

                                                    {dtStatus === 'AP' &&
                                                        <span className="u-label u-label--sm g-bg-lightred g-rounded-20 g-px-10 g-font-size-13"> โค้ดนี้ถูกใช้งานแล้ว </span>
                                                    }
                                                    <p>
                                                        ใช้งานภายในวันที่ :  {moment(dtEndDate).format("MMM DD YYYY")}
                                                    </p>
                                                </div>

                                                <ul className="nav nav-tabs" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" data-toggle="tab" href="#tabs-1" role="tab" aria-selected="true">CODE</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" data-toggle="tab" href="#tabs-2" role="tab" aria-selected="false">BARCODE</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" data-toggle="tab" href="#tabs-3" role="tab" aria-selected="false">QR CODE</a>
                                                    </li>
                                                </ul>

                                                <div className="tab-content">
                                                    <div className="tab-pane g-height-150 g-px-50 active" id="tabs-1" role="tabpanel">
                                                        <div className="h1 g-font-size-48 g-px-50- g-pt-50">
                                                            <div className="h1 "> {dtCode}</div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane g-height-120 g-w" id="tabs-2" role="tabpanel">
                                                        <div className="h1 g-font-size-48 g-px-50- g-pt-50">
                                                            <p className="lead">
                                                                <p><GenBarcode value={dtCode} /></p>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane g-height-150" id="tabs-3" role="tabpanel">
                                                        <div className="h1 g-font-size-48 g-px-50- g-pt-50">
                                                            <p className="lead">

                                                                <p><GenQRCode value={dtCode} /></p>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default MyCouponView;