import React from "react";

import Menu from "../menu/Menu";

import OneSmilePic from '../../assets/img/web/OneSmilePlus.jpg'


const Home = () => {

    return (
        <>

            <Menu />
            <div>
                <section class="container  g-pt-50 ">


                    <div class="row justify-content-center">
                        <div class="col-lg-12 order-lg-12 g-mb-10-">
                            <img class=" img-fluid" src={OneSmilePic} />
                        </div>
                    </div>

                </section>
            </div>
        </>
    );

}

export default Home;