import React, { useEffect, useState } from "react";
import Menu from "../menu/Menu";
import swal from 'sweetalert';
import axios from "axios";
import API from '../../config.json'
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import './Style.css'
import Overlay from "../helper/Overlay";

import ProfileSetting from "./ProfileSetting";
import ProfileMenu from "./ProfileMenu";
import { Fade } from "react-bootstrap";

const Profile = () => {

    const navigate = useNavigate();
    const [userToken, setUserToken] = useState(localStorage.getItem("userToken"));

    const [userFname, setUserFname] = useState('Loading.....');
    const [userLname, setUserLname] = useState('...........');
    const [userPhone, setUserPhone] = useState('XXXXXXXXXX');
    const [line_displayName, setLine_displayName] = useState('');
    const [lineConnectStatus, setLineConnectStatus] = useState(false);


    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);


    useEffect(() => {
        document.title = 'บัญชี';
        const data = {
            'userID': userToken,

        }


        axios.post(`${API.SERVER_URL}/user-info`, data,
            {
                headers: {
                    'Authorization': `Basic ${API.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                setUserFname(res.data.userInfo['userFname'])
                setUserLname(res.data.userInfo['userLname'])
                setUserPhone(res.data.userInfo['userPhone'])
                setLine_displayName(res.data.userInfo['line_displayName'])
                if (res.data.userInfo['line_displayName']) {
                    setLineConnectStatus(true)
                }
                setIsLoading(false)

            })
            .catch(err => {
                console.log(err)
                swal("An error occurred. Please try again later.", "warning");
                setIsLoading(false)
            })
            ;
    }, [])


    return (
        <>
            <Menu />
            <Overlay enabled={isLoading} />
            <section
                className="g-flex-centered g-height-500- g-bg-img-hero u-bg-overlay g-bg-black  g-bg-black-graient -g-bg-black-opacity-0_7--after g-color-white-opacity-0_7 g-py-20">
                <div className="container u-bg-overlay__inner">
                    <div className="row">
                        <div className="col-md-6 align-self-center g-py-20">
                            {/* <img className="w-100" src="https://media-mapps.mcardmall.com/assets/card-types/mcard-2018.png" alt="Iamge Description" /> */}
                            <div className="d-flex justify-content-center container text-white mt-5-">
                                <div className="card-member p-2 px-3 py-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <img src="https://i.imgur.com/8ANWXql.png" width="20" height="20" />
                                        {/* <img src="https://i.imgur.com/SsTSozr.png" width="40" /> */}
                                    </div>
                                    <div className="mt-3">
                                        {userPhone}
                                        {/* <span className="mr-3">****</span>
                                        <span className="mr-3">****</span>
                                        <span className="mr-3">****</span>
                                        <span className="mr-2">5513</span> */}
                                    </div>
                                    <div className="d-flex justify-content-between card-details mt-3 mb-3">
                                        <div className="d-flex flex-column">
                                            <span className="light g-color-white"></span>
                                            <span>{userFname + ' ' + userLname}</span>
                                        </div>
                                        <div className="d-flex flex-row  ">
                                            <div className="d-flex flex-column mr-3">
                                                <span className="light g-color-white">คะแนนคงเหลือ</span><span>0</span>
                                            </div>
                                            {/* <div className="d-flex flex-column">
                                                <span className="light g-color-white">CVV</span><span>857</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-6 align-self-center g-py-20 d-none d-lg-bock d-md-block">
                            <h2 className="h5 text-uppercase g-letter-spacing-1 g-color-white g-mb-5">สวัสดี</h2>
                            <h2 className="h4 text-uppercase g-letter-spacing-1 g-color-white g-mb-5">{userFname + ' ' + userLname}</h2>
                            <p>
                                <Link
                                    to="/profile-view"
                                    className="d-none u-label g-rounded-20 g-bg-primary g-px-15 g-mr-10 g-color-white--hover g-text-underline--none--hover">
                                    อัพเดทโปรไฟล์
                                </Link>
                            </p>

                            <p className="lead mb-0 g-line-height-2 d-none d-lg-bock d-md-block g-color-white">
                                คะแนนคงเหลือ : 0
                            </p>

                            <p className="d-none">
                                <Link
                                    to="/logout"
                                    className="u-label g-rounded-20 g-bg-primary g-px-15 g-mr-10 g-color-white--hover  g-text-underline--none--hover">
                                    ออกจากระบบ
                                </Link>
                            </p>

                        </div>
                    </div>
                </div>


            </section>


            <section className=" g-py-20">

                <ProfileMenu lineConnectStatus={lineConnectStatus} />

            </section>


        </>
    );

}

export default Profile