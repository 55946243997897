import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import API from '../../config.json'
import moment from "moment";
import Menu from "../menu/Menu";
import swal from 'sweetalert';
import MyCouponNotFound from "./MyCouponNotFound";
import Overlay from "../helper/Overlay";
const liff = window.liff;

const MyCoupon = () => {
    // get-campaign-all-redeemDT
    // const [userToken, setUserToken] = useState(localStorage.getItem("userToken"));
    const [userToken, setUserToken] = useState();
    const [myCoupon, setMyCoupon] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [idToken, setIdToken] = useState("");
    const [userExists, setUserExists] = useState(true);
    const [error, setError] = useState(false);
    useEffect(() => {
        document.title = 'คูปองของฉัน';
        initLine()
        // geMyCoupon(11796)
    }, [])

    /////////////////// LINE Liff
    const initLine = () => {
        // onesmileplus.com 1657293895-nbljyKle
        // MANOONPAN-Login 1660820176-JjwoVyxV
        // checkLogin('U8880ed9889d756222c84a63ae6993cb6')

        setIsLoading(true)

        liff.init({ liffId: '1661303525-PwYvYK1G' }, () => {
            if (liff.isLoggedIn()) {
                runApp();
            } else {
                liff.login();
            }
        }, err => console.error(err));
    }

    const runApp = () => {
        const idToken = liff.getIDToken();
        setIdToken(idToken);

        liff.getProfile()
            .then(profile => {
                console.log(profile);
                checkLogin(profile.userId);
            })
            .catch(err => console.error(err));
    }

    const checkLogin = (userId) => {

        setUserExists(false)
        setUserExists(true)

        const data = {
            'line_userId': userId
            // 'ip': ip
        }
        // console.log(data);
        axios.post(`${API.SERVER_URL}/user-login-line`, data,
            {
                headers: {
                    'Authorization': `Basic ${API.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                // console.log(res.data)
                // console.log(res.data.userInfo)
                if (res.data['status']) {
                    setUserExists(true)
                    setUserToken(res.data.userInfo['userID']);
                    localStorage.setItem("userTokenExp", Date.now() + 1000);
                    localStorage.setItem("userToken", res.data.userInfo['userID']);
                    localStorage.setItem("fname", res.data.userInfo['userFname']);
                    localStorage.setItem("lname", res.data.userInfo['userLname']);

                    if (res.data.userInfo['userID']) {
                        geMyCoupon(res.data.userInfo['userID'])
                    }


                } else {
                    setUserExists(false)
                    setIsLoading(false)
                    Navigate("/signup-page");
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                swal("An error occurred. Please try again later.", "warning");
                setError('An error occurred. Please try again later.');
            })
            ;
    }

    /////////////////////////////
    const geMyCoupon = (userID) => {
        const data = {
            'userID': userID
        }
        axios.post(`${API.SERVER_URL}/get-campaign-all-redeemDT`, data,
            {
                headers: {
                    'Authorization': `Basic ${API.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                setIsLoading(false)
                console.log(res.data.length)
                if (res.data.length >= 1) {
                    setMyCoupon(res.data)
                }
            })
            .catch(err => {
                setIsLoading(false)
                console.log(err)
            });
    }

    return (
        <>

            <Overlay enabled={isLoading} />
            <Menu />
            <section>
                <div className="container g-pt-50 g-pb-70">

                    <header className="text-center g-width-60x--md mx-auto g-mb-20  d-none">
                        <div className="u-heading-v2-3--bottom g-brd-primary g-mb-5">
                            <h2 className="h4 u-heading-v2__title g-color-gray-dark-v2 text-uppercase g-font-weight-600">
                                คูปองของฉัน
                            </h2>
                        </div>
                    </header>

                    {myCoupon && myCoupon.length > 0 ? (
                        <div className="row">
                            {myCoupon.map((item, key) => (
                                <div className="col-md-6 g-mb-1" key={key}>
                                    <Link
                                        key={item.dtKey}
                                        to={
                                            {
                                                pathname: `/my-coupon-view/${item.dtKey}/${item.campID}`,
                                                state: item.dtKey
                                            }
                                        }>
                                        <article className="d-flex justify-content-between align-items-center u-shadow-v19 g-bg-white rounded g-pa-20">
                                            <div className="d-block g-max-width-100 g-mr-20 " >
                                                <img className="g-width-30 g-height-30 g-rounded-50x" src={item.comPic_url + '/' + item.comPic} loading="lazy" />
                                            </div>
                                            <div className="g-mr-20 text-left">
                                                <h3 className="g-color-black g-font-weight-700 g-font-size-10 text-uppercase">
                                                    <div className="u-link-v5 g-color-black g-color-primary--hover" >
                                                        {item.campNameTH}
                                                    </div>
                                                    <p> <i className="fa fa-clock-o"></i> {moment(item.dtEndDate).format("MMM DD YYYY")} </p>
                                                </h3>
                                            </div>
                                            <div className="d-block ">

                                                {item.dtStatus === 'EX' &&
                                                    <strong className="g-font-size-10 u-label u-label--sm g-bg-lightred g-rounded-20 g-px-10">หมดอายุ</strong>
                                                }

                                                {item.dtStatus === 'AP' &&
                                                    <strong className="g-font-size-10 u-label u-label--sm g-bg-teal g-rounded-20 g-px-10">ใช้งานแล้ว</strong>
                                                }

                                                {item.dtStatus === 'AC' &&
                                                    <strong className="g-font-size-10 u-label u-label--sm g-bg-blue g-rounded-20 g-px-10">ใหม่</strong>
                                                }

                                            </div>
                                        </article>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="row">
                            <MyCouponNotFound />
                        </div>
                    )}

                </div>
            </section>

        </>
    );

}

export default MyCoupon;