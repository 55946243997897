import React, { useEffect, useState } from "react";
import smileplusMockup from '../../assets/img-web/smileplus-mockup-login.png'
import lineIcon from '../../assets/img/icons/btn_base.png'
import swal from 'sweetalert';
import axios from "axios";
import API from '../../config.json'
import Onesmileplus from '../../assets/img/bg/onesmileplus.jpg'


import Overlay from "../helper/Overlay";
import { Link } from "react-router-dom";



const Forgotpassword = () => {
    document.title = 'ลืมรหัสผ่าน';
    const [userName, setUsername] = useState('');
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = async (e) => {
        setIsLoading(true)
        e.preventDefault();
        const data = {
            'userName': userName,
        }
        // console.log(data);
        await axios.post(`${API.SERVER_URL}/user-forgotpassword`, data,
            {
                headers: {
                    'Authorization': `Basic ${API.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                // console.log(res)
                setIsLoading(false)
                if (res.data.error) {
                    swal(res.data.data['messages'], "", "warning");
                } else {
                    swal(res.data.data['messages'], "", "success");
                    setUsername('');
                }
            })
            .catch(err => {
                console.log(err)
                swal("An error occurred. Please try again later.", "warning");
                setError('An error occurred. Please try again later.');
                setIsLoading(false)
            });

    }

    return (
        <>
            <Overlay enabled={isLoading} />
            <section className="overflow-hidden">
                <div className="row no-gutters g-px-100-">
                    <div className="col-lg-6 d-none d-lg-block">
                        <div className=" js-carousel h-100 slick-initialized slick-slider" data-autoplay="true" data-infinite="true" data-fade="true" data-speed="5000">
                            <div className="slick-list draggable">
                                <div className="slick-track" style={{ opacity: '1', width: '2020px' }}>
                                    <div
                                        className="js-slide g-bg-size-cover h-100vh g-min-height-100vh slick-slide slick-current slick-active"
                                        style={{ backgroundImage: `url(${Onesmileplus})`, "width": "1010px", "position": "relative", "left": "0px", "top": "0px", "zIndex": "999", "opacity": "1", "height": "calc(100vh - 75.5px)" }}
                                        data-calc-target="#js-header"
                                        data-slick-index="0" aria-hidden="false" tabIndex="0"></div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="g-pa-50 g-mx-70--xl">
                            <form className="g-py-15" onSubmit={handleSubmit}>
                                <h2 className="h3 g-color-black mb-4">ลืมรหัสผ่าน</h2>
                                <h3 className="h4 g-color-black mb-4"> รีเซ็ตรหัสผ่านด้วยอีเมลที่คุณใช้งาน </h3>
                                <div className="mb-4">
                                    <div className="input-group g-brd-primary--focus">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text g-width-45 g-brd-right-none g-brd-gray-light-v4 g-color-primary"><i className="icon-finance-067 u-line-icon-pro g-pos-rel g-top-2"></i></span>
                                        </div>
                                        <input
                                            className="form-control g-color-black g-brd-left-none g-bg-white g-brd-gray-light-v4 g-pl-0 g-pr-15 g-py-15"
                                            type="email"
                                            value={userName}
                                            onChange={e => setUsername(e.target.value)}
                                            placeholder="อีเมล"
                                            required />
                                    </div>
                                </div>
                                <div className="row justify-content-between mb-4">
                                    <div className="col align-self-center text-right">
                                        <Link
                                            to="/login"
                                            className="g-color-gray-dark-v4 g-color-primary--hover" >เข้าสู่ระบบ</Link>
                                    </div>
                                </div>

                                <div className="g-mb-50">
                                    <button
                                        className="btn btn-block g-font-size-18  g-font-weight-600 g-py-16 g-px-25 btn-onesmile  "
                                        type="submit">รีเซ็ตรหัสผ่าน
                                    </button>
                                </div>
                                <p
                                    className="g-font-size-13 text-center mb-0">ไม่ได้เป็นสมาชิก ?
                                    <Link
                                        to="/signup"
                                        className="g-font-weight-600">
                                        สมัครสมาชิก OneSmile+
                                    </Link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );

}

export default Forgotpassword;