import React from "react";
import Menu from "../menu/Menu";

const MyCouponNotFound = () => {

    return (
        <>
            <Menu />
            <div className="container text-center g-py-100">
                <div className="mb-5">
                    <span className="d-block g-color-gray-light-v1 g-font-size-70 g-font-size-90--md mb-4">
                        <i className="icon-tag u-line-icon-pro"></i>
                    </span>
                    <h4 className="g-mb-30">ไม่พบคูปอง</h4>
                    <p>คุณสามารถรับแลกสิทธิพิเศษได้ที่เมนู "แลกสิทธิพิเศษ"</p>
                </div>
            </div>
        </>
    )
}

export default MyCouponNotFound;