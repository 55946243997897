import React from "react";
import QRCode from 'react-qr-code';

function GenQRCode(text) {
    console.log(text.value)
  return (
    <div>
      <QRCode value={text.value} size={256} text={text.value}  />
    </div>
  );
}

export default GenQRCode;