import React from "react";

import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";


const Homepage = () => {

    return (
        <>
            <Header />
            <Body />
            <Footer />
        </>
    )
}

export default Homepage;