import React from "react";


import Barcode from 'react-barcode';

function GenBarcode(text) {
    // console.log(text.value)
    // const [data,setData] = useState(text.value)

    return (
        <div>
            <Barcode value={text.value} width={1.5} height={50} text={text.value} />
        </div>
    );
}

export default GenBarcode;