import React from "react";
import Logo from '../../assets/img/logo/logo.png'

import { Link } from "react-router-dom";


import './Style.css'

const Menu = () => {

    return (
        <>
            <header id="js-header" className="d-none d-md-block  d-lg-block  u-header u-header--static u-header--show-hide u-header--change-appearance u-header--untransitioned" data-header-fix-moment="500" data-header-fix-effect="slide">
                <div className="u-header__section u-header__section--light g-transition-0_3 g-bg-white g-py-10 u-shadow-v18" data-header-fix-moment-exclude="g-bg-white g-py-10" data-header-fix-moment-classnamees="g-bg-white-opacity-0_7 u-shadow-v18 g-py-0">
                    <nav className="navbar navbar-expand-lg">
                        <div className="container">
                            <button className="navbar-toggler navbar-toggler-right btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-top-3 g-right-0" type="button" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navBar" data-toggle="collapse" data-target="#navBar">
                                <span className="hamburger hamburger--slider">
                                    <span className="hamburger-box">
                                        <span className="hamburger-inner"></span>
                                    </span>
                                </span>
                            </button>
                            <Link to="/promotion" className="navbar-brand">
                                <img src={Logo} style={{ width: '80px' }} />
                            </Link>

                            <div className="collapse navbar-collapse align-items-center flex-sm-row g-pt-10 g-pt-5--lg" id="navBar">
                                <ul className="navbar-nav text-uppercase g-font-weight-600 ml-auto">
                                    <li className="nav-item g-mx-20--lg d-none">
                                        <Link to="/home" className="nav-link px-0">
                                            หน้าหลัก
                                        </Link>
                                    </li>
                                    <li className="nav-item g-mx-20--lg">
                                        <Link to="/promotion" className="nav-link px-0">
                                            โปรโมชั่น
                                        </Link>
                                    </li>

                                    <li className="nav-item g-mx-20--lg">
                                        <Link to="/my-coupon" className="nav-link px-0">
                                            คูปองของฉัน
                                        </Link>
                                    </li>
                                    <li className="nav-item g-mx-20--lg">
                                        <Link to="/profile" className="nav-link px-0">
                                            บัญชี
                                        </Link>
                                    </li>
                                    {/* <li className="nav-item g-ml-20--lg g-mr-0--lg">
                                        <a href="#" className="nav-link px-0">What's New

                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>

        
        </>
    );
}

export default Menu;