import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";



const ProfileMenu = (props) => {

    // console.log(props.lineConnectStatus)

    // const  lineConnectStatus  = useState(props.lineConnectStatus)


    // useEffect(()=>{
    //     console.log(props.lineConnectStatus)

    // },[])


    return (
        <>
            <div className="container">
                <div className="list-group list-group-border-0 g-mb-40">
                    <Link
                        to="/profile-view"
                        className="list-group-item list-group-item-action justify-content-between">
                        <span>
                            <i className="fa fa-user-circle g-pos-rel g-top-1 g-mr-8"></i>อัพเดทโปรไฟล์
                            <span className="float-right g-ml-10">
                                <i className="fa fa-chevron-right"></i>
                            </span>
                        </span>
                    </Link>
                    <Link
                        to="/profile-changepassword"
                        className="list-group-item list-group-item-action justify-content-between">
                        <span>
                            <i className="fa fa-unlock-alt g-pos-rel g-top-1 g-mr-8"></i> อัพเดทความปลอดภัย
                            <span className="float-right g-ml-10">
                                <i className="fa fa-chevron-right"></i>
                            </span>
                        </span>
                    </Link>

                    <Link
                        to="/profile-lineconnect"
                        className="list-group-item list-group-item-action justify-content-between">
                        <span>
                            <i className="fa fa-database g-pos-rel g-top-1 g-mr-8"></i> เชื่อมต่อ Line
                            {props.lineConnectStatus && ' แล้ว'}
                            <span className="float-right g-ml-10">
                                <i className="fa fa-chevron-right"></i>
                            </span>
                        </span>
                    </Link>


                </div>
            </div>
        </>
    );
}

export default ProfileMenu;