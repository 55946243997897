import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom"

import parse from 'html-react-parser';
import moment from "moment";
import Menu from "../menu/Menu";
import axios from "axios";
import API from '../../config.json'
import swal from 'sweetalert';

import Overlay from "../helper/Overlay";


const PromotionDetail = () => {



    const navigate = useNavigate();

    const params = useParams()
    const [campID, setCampID] = useState(params.campID)
    const [userToken, setUserToken] = useState(localStorage.getItem("userToken"));

    const [campagin, setCampagin] = useState([])
    const [campPic, setCampPic] = useState(null);
    const [campNameTH, setCampNameTH] = useState(null);
    const [campDetailTH, setCampDetailTH] = useState(<p></p>);
    const [campStartDate, setCampStartDate] = useState(null);
    const [campEndDate, setCampEndDate] = useState(null);
    const [campPerUser, setCampPerUser] = useState(null);
    const [campAmount, setCampAmount] = useState(null);
    const [campTotal, setCampTotal] = useState(null);

    const [campDisabled, setCampDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);





    useEffect(() => {
        setIsLoading(true)
        const data = {
            'userID': userToken,
            'campID': campID
        }


        axios.post(`${API.SERVER_URL}/get-campaign-id`, data,
            {
                headers: {
                    'Authorization': `Basic ${API.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                // console.log(res.data.data)
                // setCampagin(res.data.data)
                setCampPic(res.data.data['campPic_url'] + '/' + res.data.data['campPic'])
                setCampNameTH(res.data.data['campNameTH'])
                setCampDetailTH(res.data.data['campDetailTH'])
                setCampStartDate(res.data.data['campStartDate'])
                setCampEndDate(res.data.data['campEndDate'])
                setCampAmount(res.data.data['campAmount'])
                setCampTotal(res.data.data['campTotal'])
                setCampPerUser(res.data.data['campPerUser'])

                if (res.data.data['campTotal'] - res.data.data['campAmount'] <= 0) {
                    setCampDisabled(true)
                    // console.log('หมดแล้ว')
                } else {
                    setCampDisabled(false)
                    // console.log('ยังไม่หมก')
                }

                setIsLoading(false)

            })
            .catch(err => {
                setIsLoading(false)
                console.log(err)
            });



    }, [])

    const redeem = () => {
        setIsLoading(true)
        const data = {
            'userID': userToken,
            'campID': campID
        }

        axios.post(`${API.SERVER_URL}/get-campaign-redeem`, data,
            {
                headers: {
                    'Authorization': `Basic ${API.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                console.log(res.data)
                console.log(res.data.campInfo['dtID'])
                console.log(res.data.campInfo['dtKey'])
                setIsLoading(false)

                navigate(`/my-coupon-view/${res.data.campInfo['dtKey']}/${res.data.campInfo['dtID']}`);
                //         if (res.data.campInfo['total'] == 'notfull') {
                // } else {

                //             alert('full')
                //         }
                // window.location.href = `/my-coupon-view/${res.data.campInfo['dtKey']}/${res.data.campInfo['dtID']}`

            })
            .catch(err => {
                swal("An error occurred. Please try again later.", "warning");
                setIsLoading(false)
                console.log(err)
            });


    }

    return (
        <>
            <Menu />
            <Overlay enabled={isLoading} />

            <section>




                <div className="container g-pt-50 g-pb-70">
                    <div className="shortcode-html">
                        <div className="row">
                            <div className="col-lg-6 g-mb-10 g-rounded-20">
                                <img className="img-fluid w-100 g-rounded-20 " src={campPic} alt={campNameTH} loading="lazy" />
                            </div>
                            <div className="col-lg-6">
                                <div className="g-px-40--lg -g-py-70" style={{ minHeight: '500' }}>
                                    <span className="d-block g-font-weight-500 g-font-size-10 ">{moment(campStartDate).format("MMM DD YYYY")} - {moment(campEndDate).format("MMM DD YYYY")}</span>
                                    <span className="d-block g-font-weight-500 g-font-size-10 ">รับสิทธิ์/เดือน/ผู้ใช้งาน : {campPerUser} </span>
                                    <span className="d-block g-font-weight-500 g-font-size-10 ">คงเหลือ : {campTotal - campAmount} </span>
                                    <div className="g-mb-10">
                                        <h3 className="g-font-weight-800 mb-4"> {campNameTH}</h3>
                                        <div className="mb-4">
                                            <h2 className="h5 text-uppercase mb-3">รายละเอียด</h2>
                                            <div className="g-width-60 g-height-1 g-bg-black"></div>
                                        </div>
                                        <p>{parse('<p>' + campDetailTH + '</p>')}</p>
                                    </div>
                                    <div className="row g-mx-minus-5 g-mb-20">
                                        <div className="col g-px-5 g-mb-10">
                                            <button


                                                className="btn btn-block btn-onesmile g-color-white--hover g-font-size-12 text-uppercase g-py-15 g-px-25"
                                                onClick={redeem}
                                                disabled={campDisabled}

                                                type="button">
                                                รับสิทธิ
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </section>







        </>
    );
}

export default PromotionDetail;