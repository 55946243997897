import React, { useState } from 'react';
import { LineLogin } from 'reactjs-line-login';
import 'reactjs-line-login/dist/index.css';

const SignupLine = () => {
    const [payload, setPayload] = useState(null);
    const [idToken, setIdToken] = useState(null);

    /*
    Example:
      clientID='1854553430'
      clientSecret='deee1bf0ae8deg658e214b67b25f6ec3'
      state='b41c8fd15b895f0fc28bfwb9d7da89054d931e7s'
      nonce='d78a51235f6ee189e831q9c68523cfa336917ada'
      redirectURI='http://localhost:3000/callback"
      scope='profile openid email'
    recommend : save secret in .env
    */

    return (
        <div>
            <LineLogin
                clientID='1660820176'
                clientSecret='8f1dd77ee154db56ff8912dbd867c6cc'
                state=''
                nonce=''
                redirectURI='https://8c51-58-11-95-25.ap.ngrok.io/callback'
                scope='profile openid email'
                setPayload={setPayload}
                setIdToken={setIdToken}
            />
            {payload && idToken ? (
                console.log('payload', payload, 'idToken', idToken)
            ) : (
                <></>
            )}
        </div>
    );
};


export default SignupLine;

