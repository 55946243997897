import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import axios from "axios";
import API from '../../config.json'

import { useNavigate } from "react-router-dom";
import Overlay from "../helper/Overlay";



// import liff from '@line/liff';
const liff = window.liff;

function ProfileLineConnect() {
    document.title = 'เข้าสู่ระบบ';

    const navigate = useNavigate();
    const [userToken, setUserToken] = useState(localStorage.getItem("userToken"));


    const [pictureUrl, setPictureUrl] = useState('logo');
    const [idToken, setIdToken] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [statusMessage, setStatusMessage] = useState("");
    const [userId, setUserId] = useState("");
    const [error, setError] = useState(false);
    const [userExists, setUserExists] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [ip, setIP] = useState('');
    // const getData = async () => {
    //     const res = await axios.get('https://geolocation-db.com/json/')
    //     // console.log(res.data);
    //     // console.log(res.data.IPv4);
    //     setIP(res.data.IPv4)
    // }


    useEffect(() => {

        console.log('LineConnect');
        // setUserExists(true)
        initLine();
        // getData()
    }, []);

    const logout = () => {
        liff.logout();
        window.location.reload();
    }

    const initLine = () => {

        // onesmileplus.com 1657293895-nbljyKle
        // MANOONPAN-Login 1660820176-JjwoVyxV

        setIsLoading(true)
        liff.init({ liffId: '1661303525-Nv626M1w' }, () => {
            if (liff.isLoggedIn()) {
                runApp();
            } else {
                liff.login();
            }
        }, err => console.error(err));
    }

    const runApp = () => {
        const idToken = liff.getIDToken();
        setIdToken(idToken);

        liff.getProfile()
            .then(profile => {
                // console.log(profile);
                setDisplayName(profile.displayName);
                setPictureUrl(profile.pictureUrl);
                setStatusMessage(profile.statusMessage);
                setUserId(profile.userId);
                setIsLoading(false)
                lineConnect(profile);

            })
            .catch(err => console.error(err));
    }



    const lineConnect = (profile) => {


        const data = {
            'userID': userToken,
            'line_userId': profile.userId,
            'line_email': '',
            'line_displayName': profile.displayName,
            'line_statusMessage': profile.statusMessage,
            'line_pictureUrl': profile.pictureUrl,
        }


        axios.post(`${API.SERVER_URL}/user-info-lineconnect`, data,
            {
                headers: {
                    'Authorization': `Basic ${API.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {

                if (!res.data.error) {
                    swal("เชื่อมต่อบัญชีสำเร็จ", "success");
                    navigate("/profile");

                } else {
                    swal(res.data.message['line_userId'], '', "warning");
                    navigate("/profile");


                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
                swal("An error occurred. Please try again later.", "warning");
                setError('An error occurred. Please try again later.');
            });

    }

    return (

        <>

            <Overlay enabled={isLoading} />

            <div className="App container -d-none">
                <header className="App-header g-pt-50">
                    <div style={{ textAlign: "center" }}>
                        <img src={pictureUrl} width="300px" height="300px" />
                        <div className='g-pt-10'>
                            <p className='d-none'><b>id token: </b> {idToken}</p><br/>
                            <b>display name: </b> {displayName} <br/>
                            <b>status message: </b> {statusMessage}<br/>
                            <b>user id: </b> {userId}
                        </div>
                        <button className='d-none' onClick={() => logout()} style={{ width: "100%", height: 30 }}>Logout</button>
                    </div>
                </header>
            </div>
        </>

    );
}

export default ProfileLineConnect;