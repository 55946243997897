import { useEffect } from "react";
 import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();


    useEffect(() => {
        // Remove the user's login data from localStorage

        // localStorage.removeItem("userTokenExp");
        // localStorage.removeItem("userToken");
        // localStorage.removeItem("username");
        // localStorage.removeItem("fname");
        // localStorage.removeItem("lname");
        // localStorage.removeItem("picture");
        // localStorage.removeItem("picture_path");

        localStorage.clear();
        navigate("/");
        // history.push("/login");
        // window.location.href = '/';
    }, []);

    // return <button onClick={handleLogout}>Logout</button>;
};

export default Logout;