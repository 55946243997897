import React, { useEffect, useState } from "react";
import lineIcon from '../../assets/img/icons/btn_base.png'
import Overlay from "../helper/Overlay";
import { Link } from "react-router-dom";
import Onesmileplus from '../../assets/img/bg/onesmileplus.jpg'
import LoginMobile from "./LoginMobile";
import LoginEmail from "./LoginEmail";

const _LoginMain = () => {

    document.title = 'เข้าสู่ระบบ';

    const [loginType, setLoginType] = useState('email')
    const [loginTypeTitle, setLoginTypeTitle] = useState('เข้าสู่ระบบด้วยอีเมล')
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (loginType == 'email') {
            setLoginTypeTitle('เข้าสู่ระบบด้วยอีเมล');
        } else {
            setLoginTypeTitle('เข้าสู่ระบบด้วยหมายเลขโทรศัพท์');
        }

    }, [loginType])




    return (
        <>
            <Overlay enabled={isLoading} />
            <section className="overflow-hidden">
                <div className="row no-gutters g-px-100-">
                    <div className="col-lg-6 d-none d-lg-block  ">
                        <div className=" js-carousel h-100 slick-initialized slick-slider" data-autoplay="true" data-infinite="true" data-fade="true" data-speed="5000">
                            <div className="slick-list draggable">
                                <div className="slick-track" style={{ opacity: '1', width: '2020px' }}>
                                    <div
                                        className="js-slide g-bg-size-cover h-100vh g-min-height-100vh slick-slide slick-current slick-active"
                                        style={{ backgroundImage: `url(${Onesmileplus})`, "width": "1010px", "position": "relative", "left": "0px", "top": "0px", "zIndex": "999", "opacity": "1", "height": "calc(100vh - 75.5px)" }}
                                        data-calc-target="#js-header"
                                        data-slick-index="0" aria-hidden="false" tabIndex="0"></div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="g-pa-50 g-mx-70--xl">

                            <h2 className="h5 g-color-black mb-4">ยินดีที่ได้พบคุณอีกครั้ง</h2>
                            <h3 className="h4 g-color-black mb-4">   {loginTypeTitle} </h3>

                            {loginType == 'mobile' && <LoginMobile />}
                            {loginType == 'email' && <LoginEmail />}

                            <div className="text-right">
                                <p className="g-color-gray-dark-v5 mb-0">
                                    <Link
                                        className="g-font-weight-600 g-font-size-14"
                                        to="/forgot-password">ลืมรหัสผ่าน ?</Link>
                                </p>
                            </div>



                            <div className="text-center g-pos-rel pb-2 g-mb-50">
                                <div className="d-inline-block g-width-200 g-height-1 g-bg-gray-light-v4"></div>
                                <span className="u-icon-v2 u-icon-size--lg g-brd-gray-light-v4 g-color-gray-dark-v4 g-bg-white g-font-size-default rounded-circle text-uppercase g-absolute-centered g-pa-24">หรือ</span>
                            </div>




                            <div className="g-mb-10">
                                <Link
                                    className="btn btn-block g-font-size-18 g-rounded-50  g-font-weight-600 g-py-10 g-px-25  btn-linelogin  g-color-white"
                                    to="/line"  >
                                    <img width="35" src={lineIcon} />
                                    เข้าสู่ระบบด้วย LINE
                                </Link>
                            </div>

                            <div className="g-mb-10">
                                <button
                                    onClick={e => setLoginType('email')}
                                    type="button"
                                    className="btn btn-block u-btn-outline-darkgray g-color-white--hover g-rounded-50 text-uppercase g-py-13 g-px-30 mr-1">
                                    <i className="mr-1 fa fa fa-envelope"></i>
                                    เข้าสู่ระบบด้วยอีเมล
                                </button>
                            </div>

                            <div className="g-mb-10">
                                <button
                                    onClick={e => setLoginType('mobile')}
                                    type="button"
                                    className="btn btn-block u-btn-outline-darkgray g-color-white--hover g-rounded-50 text-uppercase g-py-13 g-px-30 mr-1">
                                    <i className="mr-1 fa fa-phone-square"></i>
                                    เข้าสู่ระบบด้วยหมายเลขโทรศัพท์
                                </button>
                            </div>

                            <p
                                className="g-font-size-13 text-center mb-0">ไม่ได้เป็นสมาชิก ?
                                <Link
                                    to="/signup"
                                    className="g-font-weight-600">
                                    สมัครสมาชิก OneSmile+
                                </Link>
                            </p>

                            <div className="row justify-content-between mb-5-">
                                <div className="col align-self-center">
                                    <div className="col-md-12 form-group g-mb-40 ju  text-center">
                                        <label className="form-check-label" htmlFor="invalidCheck">
                                            <Link
                                                to="/privacy"
                                                className="text-underline"
                                                target="_blank" >
                                                รายละเอียดนโยบายความเป็นส่วนตัว
                                            </Link>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );

}

export default _LoginMain;